import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { createIndexedDBStorage } from '../../api/indexed-db/zustandIndexedDBStorage'
import { Team } from '../../types'

interface TeamStore {
    team: Team
    setTeam: (team: Team) => void
}

const STORAGE_KEY = 'viscap_ai_team_store'

export const useTeamStore = create<TeamStore>()(
    persist(
        (set) => ({
            team: null,
            setTeam: (team) =>
                set({
                    team,
                }),
        }),
        {
            name: STORAGE_KEY,
            partialize: (state) => ({
                team: state.team,
            }),
            storage: createIndexedDBStorage(),
        }
    )
)
