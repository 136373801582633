import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
    writeBatch,
} from 'firebase/firestore'
import { db } from '../../../firebase_config/firebase_config'
import { Team, UserTeamEntry } from '../../../types'
import { omit } from 'lodash'
import { constructTeamsRoute } from '../../axios/routes'
import { get, post, put } from '../../axios/request'

export const getTeamsByIds = async (teamEntries: Partial<UserTeamEntry>[]): Promise<Team[]> => {
    const url = constructTeamsRoute()
    const res: Team[] = await post<Team[]>(`${url}/ids`, { teamEntries })
    return res
}

export const getTeam = async (teamId: string): Promise<Team> => {
    const url = constructTeamsRoute()
    const res: Team = await get<Team>(`${url}/${teamId}`)
    return res
}

export const checkTeamExists = async (teamEmail: string): Promise<boolean> => {
    try {
        const res = await getDocs(query(collection(db, 'teams'), where('email', '==', teamEmail)))
        return res.docs.length > 0
    } catch (error) {
        console.log(error)
    }
}

export const revokeTeamAccess = async (teamId, brandId) => {
    const url = constructTeamsRoute()
    await post(`${url}/revoke-access`, { teamId, brandId })
}

export const saveTeam = async (team) => {
    const url = constructTeamsRoute()
    await put<{ updatedDoc: Team }>(`${url}/save`, { team })
}

export const getTeamByAuditionId = async (auditionId) => {
    try {
        const res = await getDocs(query(collection(db, 'teams'), where('auditionFormSettings.uuid', '==', auditionId)))
        return (res.docs?.[0].data() as Team) || null
    } catch (error) {
        throw new Error(error)
    }
}

export const updateName = async (teamId, name) => {
    const url = constructTeamsRoute()
    await put(`${url}/${teamId}/name`, { name })
}

export const updateLogo = async (teamId, logoURL) => {
    const url = constructTeamsRoute()
    await put(`${url}/${teamId}/logo`, { logoURL })
}

const acceptAction = (index, list) => {
    const updatedList = [...list]
    updatedList.splice(index, 1, omit(updatedList[index], 'isPending'))
    return updatedList
}

const dismissAction = (index, list) => {
    const updatedList = [...list]
    updatedList.splice(index, 1)
    return updatedList
}

const inviteActions = async (teamId, brandId, notificationId, callback) => {
    const msgRef = doc(db, 'teams', teamId, 'notifications', notificationId)
    if (!(await getDoc(msgRef)).exists()) {
        return
    }

    const teamRef = doc(db, 'teams', teamId)
    const team = (await getDoc(teamRef)).data()

    const brandIndex = team.brands.findIndex((el) => el.id === brandId)
    await updateDoc(teamRef, { brands: callback(brandIndex, team.brands) })

    const brandRef = doc(db, 'brands', brandId)
    const brand = (await getDoc(brandRef)).data()

    const teamIndex = brand.teams.findIndex((el) => el.id === teamId)
    await updateDoc(brandRef, { teams: callback(teamIndex, brand.teams) })

    await deleteDoc(msgRef)
}

export const acceptInviteToBrand = async (params: { teamId: string; brandId: string; notificationId: string }) => {
    const { teamId, notificationId, brandId } = params
    return inviteActions(teamId, brandId, notificationId, acceptAction)
}

export const dismissInviteToBrand = async (params: { teamId: string; brandId: string; notificationId: string }) => {
    const { teamId, notificationId, brandId } = params
    return inviteActions(teamId, brandId, notificationId, dismissAction)
}

export const transferOwnership = async (team: Team, oldOwnerId, newOwnerId): Promise<void> => {
    const url = constructTeamsRoute()
    await post(`${url}/transfer-ownership`, { team, oldOwnerId, newOwnerId })
}

type MediaTransferStartArgs = {
    teamId: string
    brandId: string
    uid: string
    service: string
    folders: string[]
    autoTag: string
}

export const firestoreTeamStartMediaTransfer = async (args: MediaTransferStartArgs) => {
    const { teamId, brandId, uid, service, folders, autoTag } = args

    const ref = await addDoc(collection(db, `teams/${teamId}/mediaTransfer`), {
        status: 'pending',
        brandId,
        uid,
        service,
        folders,
        autoTag,
        total: 0,
        completed: 0,
    })

    return ref.id
}

export const firestoreTeamCancelMediaTransfer = async (teamId: string, jobId: string) => {
    const ref = doc(db, `teams/${teamId}/mediaTransfer`, jobId)

    try {
        await updateDoc(ref, { status: 'cancelled' })
    } catch (error) {
        console.log(error)
    }
}

export const firestoreTeamSaveNamingConventionPreset = async (teamId: string, name: string, preset: any) => {
    try {
        const url = constructTeamsRoute()
        await post(`${url}/${teamId}/presets/naming-convention`, { name, preset })
    } catch (error) {
        console.log(error)
    }
}
