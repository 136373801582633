import { useCollection, useCollectionCount, useCollectionGroup } from '@tatsuokaniwa/swr-firestore'
import { BrandLibraryTalentData, TeamLibraryTalentData } from '../types'

const useBrandTalent = (brandId = '') => {
    const { data: brandTalent = null } = useCollection<BrandLibraryTalentData>(
        brandId ? { path: `brands/${brandId}/talentSuggestions`, parseDates: ['appliedAt'] } : null
    )

    const { data: brandTalentCount = null } = useCollectionCount<BrandLibraryTalentData>(
        brandId ? { path: `brands/${brandId}/talentSuggestions` } : null
    )

    return {
        brandTalent,
        brandTalentCount,
    }
}

export default useBrandTalent
