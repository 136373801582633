import { useCollectionGroup } from '@tatsuokaniwa/swr-firestore'
import { getCleanData } from './utils'
import { useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";

export default function useCollectionGroupClean<T = any>(...args: Parameters<typeof useCollectionGroup<T>>) {
    const { data, ...rest } = useCollectionGroup<T>(...args)

    const cleanData = useMemo(() => {
        return getCleanData<T>(data) as T[] | undefined
    }, [data])

    return {
        data: cleanData,
        ...rest,
    }
}
