export const FULL_VIDEO_TYPE = 'Full Video'

export const elementTypeArray: string[] = [
    'Hook',
    'Summary',
    'Product_Demo',
    'Product_Intro',
    'Product_Result',
    'Benefits',
    'Before',
    'Discovery',
    'Features_USP',
    'Unique_Mech',
    'End_Result',
    'Social_Proof',
    'Guarantee',
    'Agitate',
    'Authority',
    'Failed_Solution',
    'Problem',
    'Relate',
    'Use_Cases',
    'Offer',
    'CTA',
    'UGC_Talking_Point',
    'Risk_Reversal',
]

export const clipTypeArray = [
    'B-Roll',
    'F2C',
    'Voiceover',
    'Split Screen',
    'Copy Card',
    'Screen Grab',
    'Stock Footage',
    'Teleprompter',
    'Pro-F2C',
    'UGC-F2C',
    'Pro-Broll',
    'UGC-Broll',
    'Screen Recording',
    'Extra Broll',
]

export const videoTypeArray = [
    { value: 'LF-UGC', label: 'Long Form UGC' },
    { value: 'DR-UGC', label: 'DR UGC' },
    { value: 'M-UGC', label: 'Micro UGC' },
    { value: 'V-Pro', label: 'Viral Video Pro' },
    { value: 'M-Pro', label: 'Micro Pro' },
    { value: 'LF-Pro', label: 'Long Form Pro' },
    { value: 'INF', label: 'Infomercial' },
    { value: 'M-INF', label: 'Mini Infomercial' },
    { value: 'UGC-T', label: 'UGC Testimonials' },
    { value: 'VSL-LF', label: 'VSL Long Form' },
    { value: 'VSL', label: 'VSL Pro' },
    { value: 'SI', label: 'Static Image Ad' },
    { value: 'THU', label: 'Thumbnails' },
    { value: 'EL', label: 'Elements' },
]

export const storyboardFieldsArray = ['FILENAME', 'CLIPTYPE', 'SCENEDESCRIPTION', 'COPY', 'LOCATION', 'PROPS']

export const clothSizes = ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', '3xl']

export const socialMedia = ['instagram', 'linkedin', 'tiktok', 'facebook', 'youtube', 'other']

export const remoteWorkStatusOptions = [
    { value: 'inHouse', label: 'Available for in-house shoots' },
    { value: 'remoteOnly', label: 'Remote only' },
]

export const preferredContactMethods = ['email', 'phone']

export const socialMediaDomains = {
    instagram: ['instagram'],
    linkedin: ['linkedin', 'lnkd.in'],
    tiktok: ['tiktok'],
    facebook: ['facebook', 'fb.com', 'fb.me'],
    youtube: ['youtube', 'youtu.be'],
}

export const eyeColors = [
    'Amber',
    'Blue',
    'Blue-Gray',
    'Brown',
    'Dark Brown',
    'Gray',
    'Green',
    'Gray-Green',
    'Hazel',
    'Red',
    'Violet',
    'Other',
]

export const languages = [
    'English',
    'Arabic',
    'Bengali',
    'Bhojpuri',
    'Chinese - Mandarin',
    'Chinese - Wu',
    'Chinese - Yue',
    'Farsi',
    'French',
    'German',
    'Gujarati',
    'Hausa',
    'Hindi',
    'Italian',
    'Japanese',
    'Javanese',
    'Korean',
    'Marathi',
    'Portuguese',
    'Punjabi',
    'Russian',
    'Spanish',
    'Tamil',
    'Telugu',
    'Turkish',
    'Urdu',
    'Vietnamese',
]

export const englishAccents = [
    'African',
    'Australian',
    'Brummie',
    'Chinese',
    'Cockney',
    'French',
    'Geordie',
    'German',
    'Indian',
    'Italian',
    'Japanese',
    'Middle Eastern',
    'Northern Ireland',
    'Russian',
    'Scottish',
    'Spanish',
    'Welsh',
    'US - East Coast',
    'US - Mid West',
    'US - Non Regional',
    'US - Southern',
    'US - West Coast',
    'Other',
    'None',
]

export const industries = [
    'Open to all',
    'Adult',
    'Art and Entertainment',
    'Automotive and Vehicles',
    'Business and Industrial',
    'Education',
    'Family and Parenting',
    'Finance',
    'Food and Drink',
    'Health and Fitness',
    'Hobbies and Interests',
    'Home and Garden',
    'Industrial',
    'Law, Goverment and Politics',
    'Pets',
    'Real Estate',
    'Religion and Spirituality',
    'Science',
    'Shopping',
    'Society',
    'Sports',
    'Style and Fashion',
    'Technology and Computing',
    'Travel',
]

export const smartphones = [
    {
        name: 'apple',
        models: [
            'iPhone 15 Pro Max',
            'iPhone 15 Pro',
            'iPhone 15 Plus',
            'iPhone 15',
            'iPhone 14 Pro Max',
            'iPhone 14 Pro',
            'iPhone 14 Plus',
            'iPhone 14',
            'iPhone 13 Pro Max',
            'iPhone 13 Pro',
            'iPhone 13 mini',
            'iPhone 13',
            'iPhone 12 Pro Max',
            'iPhone 12 Pro',
            'iPhone 12 mini',
            'iPhone 12',
            'iPhone 11 Pro Max',
            'iPhone 11 Pro',
            'iPhone 11',
            'iPhone XS Max',
            'iPhone XS',
            'iPhone XR',
            'iPhone X',
            'iPhone 8 Plus',
            'iPhone 8',
            'iPhone 7 Plus',
            'iPhone 7',
            'iPhone 6s Plus',
            'iPhone 6s',
            'iPhone 6 Plus',
            'iPhone 6',
            'iPhone SE (3rd generation)',
            'iPhone SE (2nd generation)',
            'iPhone SE (1st generation)',
        ],
    },
    {
        name: 'samsung',
        models: [
            'Galaxy S24 Ultra',
            'Galaxy S24+',
            'Galaxy S24',
            'Galaxy S23 Ultra',
            'Galaxy S23+',
            'Galaxy S23',
            'Galaxy S22 Ultra',
            'Galaxy S22+',
            'Galaxy S22',
            'Galaxy S21 FE',
            'Galaxy S21 Ultra',
            'Galaxy S21+',
            'Galaxy S21',
            'Galaxy S20 FE',
            'Galaxy S20 Ultra',
            'Galaxy S20+',
            'Galaxy S20',
            'Galaxy S10 Lite',
            'Galaxy S10+',
            'Galaxy S10',
            'Galaxy S10e',
            'Galaxy S9+',
            'Galaxy S9',
            'Galaxy S8+',
            'Galaxy S8',
            'Galaxy S7 Edge',
            'Galaxy S7',
            'Galaxy S6 Edge+',
            'Galaxy S6 Edge',
            'Galaxy S6',
            'Galaxy Note 20 Ultra',
            'Galaxy Note 20',
            'Galaxy Note 10+ 5G',
            'Galaxy Note 10+',
            'Galaxy Note 10',
            'Galaxy Note 9',
            'Galaxy Note 8',
            'Galaxy Note 7',
            'Galaxy Note 5',
            'Galaxy Z Fold 5',
            'Galaxy Z Fold 4',
            'Galaxy Z Fold 3',
            'Galaxy Z Fold 2',
            'Galaxy Fold',
            'Galaxy Z Flip 5',
            'Galaxy Z Flip 4',
            'Galaxy Z Flip 3',
            'Galaxy Z Flip',
            'Galaxy A54',
            'Galaxy A53',
            'Galaxy A52',
            'Galaxy A51',
            'Galaxy A34',
            'Galaxy A33',
            'Galaxy A32',
            'Galaxy A24',
            'Galaxy A23',
            'Galaxy A22',
            'Galaxy A14',
            'Galaxy A13',
            'Galaxy A12',
            'Galaxy M54',
            'Galaxy M53',
            'Galaxy M52',
            'Galaxy M51',
            'Galaxy M34',
            'Galaxy M33',
            'Galaxy M32',
            'Galaxy M31',
        ],
    },
    {
        name: 'xiaomi',
        models: [
            '13 Ultra',
            '13 Pro',
            '13',
            '12 Ultra',
            '12 Pro',
            '12',
            '11 Ultra',
            '11 Pro',
            '11',
            'Mi 10 Ultra',
            'Mi 10 Pro',
            'Mi 10',
            'Mi 9',
            'Mi 8',
            'Mi Mix Fold 3',
            'Mi Mix Fold 2',
            'Mix 4',
            'Mi Mix 3',
            'Redmi Note 13 Pro+',
            'Redmi Note 13 Pro',
            'Redmi Note 13',
            'Redmi Note 12 Pro+',
            'Redmi Note 12 Pro',
            'Redmi Note 12',
            'Redmi Note 11 Pro+',
            'Redmi Note 11 Pro',
            'Redmi Note 11',
            'Redmi Note 10 Pro',
            'Redmi Note 10',
            'POCO F5 Pro',
            'POCO F5',
            'POCO F4 GT',
            'POCO F4',
            'POCO F3',
            'POCO F2 Pro',
            'POCO X5 Pro',
            'POCO X5',
            'POCO X4 GT',
            'POCO X4 Pro',
            'POCO M5',
            'POCO M4 Pro',
        ],
    },
    {
        name: 'oppo',
        models: [
            'Find X7 Ultra',
            'Find X7',
            'Find X6 Pro',
            'Find X6',
            'Find X5 Pro',
            'Find X5',
            'Find X3 Pro',
            'Find X3',
            'Find X2 Pro',
            'Find X2',
            'Reno 10 Pro+',
            'Reno 10 Pro',
            'Reno 10',
            'Reno 9 Pro+',
            'Reno 9 Pro',
            'Reno 9',
            'Reno 8 Pro',
            'Reno 8',
            'Reno 7 Pro',
            'Reno 7',
            'Reno 6 Pro',
            'Reno 6',
            'Reno 5 Pro',
            'Reno 5',
            'F21 Pro',
            'F19 Pro+',
            'F19 Pro',
            'A78',
            'A77',
            'A76',
            'A57',
            'A54',
        ],
    },
    {
        name: 'vivo',
        models: [
            'X100 Pro',
            'X100',
            'X90 Pro+',
            'X90 Pro',
            'X90',
            'X80 Pro',
            'X80',
            'X70 Pro+',
            'X70 Pro',
            'X70',
            'X60 Pro+',
            'X60 Pro',
            'X60',
            'V29 Pro',
            'V29',
            'V27 Pro',
            'V27',
            'V25 Pro',
            'V25',
            'V23 Pro',
            'V23',
            'Y100',
            'Y77',
            'Y75',
            'Y73',
            'Y35',
            'Y33',
            'Y22',
            'Y21',
        ],
    },
    {
        name: 'realme',
        models: [
            '11 Pro+',
            '11 Pro',
            '10 Pro+',
            '10 Pro',
            '9 Pro+',
            '9 Pro',
            '9',
            '8 Pro',
            '8',
            '7 Pro',
            '7',
            'GT 5 Pro',
            'GT 5',
            'GT 3 Pro',
            'GT 3',
            'GT 2 Pro',
            'GT 2',
            'GT Neo 5',
            'GT Neo 3',
            'Narzo 60 Pro',
            'Narzo 60',
            'Narzo 50 Pro',
            'Narzo 50',
        ],
    },
    {
        name: 'nothing',
        models: ['Phone (2)', 'Phone (2a)', 'Phone (1)'],
    },
    {
        name: 'google',
        models: [
            'Pixel 8 Pro',
            'Pixel 8',
            'Pixel 7 Pro',
            'Pixel 7',
            'Pixel 6 Pro',
            'Pixel 6',
            'Pixel 6a',
            'Pixel 5',
            'Pixel 5a',
            'Pixel 4 XL',
            'Pixel 4',
            'Pixel 4a 5G',
            'Pixel 4a',
            'Pixel 3 XL',
            'Pixel 3',
            'Pixel 3a XL',
            'Pixel 3a',
            'Pixel 2 XL',
            'Pixel 2',
            'Pixel XL',
            'Pixel',
        ],
    },
    {
        name: 'honor',
        models: [
            'Magic 6 Pro',
            'Magic 6',
            'Magic 5 Pro',
            'Magic 5',
            'Magic 4 Pro',
            'Magic 4',
            'Magic 3 Pro',
            'Magic 3',
            '90 Pro',
            '90',
            '80 Pro',
            '80',
            '70 Pro+',
            '70 Pro',
            '70',
            '60 Pro',
            '60',
            'X9a',
            'X8',
            'X7',
            'V30 Pro',
            'V30',
        ],
    },
    {
        name: 'motorola',
        models: [
            'Edge 40 Pro',
            'Edge 40',
            'Edge 30 Ultra',
            'Edge 30 Pro',
            'Edge 30 Neo',
            'Edge 30',
            'Edge 20 Pro',
            'Edge 20',
            'Razr 40 Ultra',
            'Razr 40',
            'Razr (2022)',
            'Razr (2020)',
            'Razr (2019)',
            'Moto G200',
            'Moto G100',
            'Moto G84',
            'Moto G73',
            'Moto G72',
            'Moto G71',
            'Moto G70',
            'Moto G54',
            'Moto G53',
            'Moto G52',
            'Moto G51',
            'Moto G50',
            'One Fusion+',
            'One Fusion',
            'One Action',
            'One Vision',
            'Moto Z4',
            'Moto Z3',
            'Moto Z2 Force',
        ],
    },
    {
        name: 'asus',
        models: [
            'ROG Phone 8 Pro',
            'ROG Phone 8',
            'ROG Phone 7 Ultimate',
            'ROG Phone 7',
            'ROG Phone 6 Pro',
            'ROG Phone 6',
            'ROG Phone 5s Pro',
            'ROG Phone 5s',
            'ROG Phone 5 Ultimate',
            'ROG Phone 5 Pro',
            'ROG Phone 5',
            'ROG Phone 3',
            'ROG Phone 2',
            'ROG Phone',
            'Zenfone 10',
            'Zenfone 9',
            'Zenfone 8 Flip',
            'Zenfone 8',
            'Zenfone 7 Pro',
            'Zenfone 7',
            'Zenfone 6',
        ],
    },
    {
        name: 'blackberry',
        models: ['Key2 LE', 'Key2', 'KeyOne', 'Priv', 'Passport', 'Classic'],
    },
    {
        name: 'tcl',
        models: ['30 SE', '30+', '30', '20 Pro 5G', '20L+', '20L', '10 Pro', '10L', 'Plex'],
    },
    {
        name: 'zte',
        models: [
            'Axon 50 Ultra',
            'Axon 50 Pro',
            'Axon 40 Ultra',
            'Axon 40 Pro',
            'Axon 30 Ultra',
            'Axon 30 Pro',
            'Axon 20 5G',
            'Blade V40',
            'Blade V30',
            'Blade A71',
            'Blade A51',
        ],
    },
]

export const locationOptions = [
    {
        value: 'usa',
        label: 'United States',
        children: [
            {
                value: 'alabama',
                label: 'Alabama',
                children: [
                    { value: 'birmingham', label: 'Birmingham', timeZone: '-5/6' },
                    { value: 'huntsville', label: 'Huntsville', timeZone: '-5/6' },
                    { value: 'montgomery', label: 'Montgomery', timeZone: '-5/6' },
                    { value: 'mobile', label: 'Mobile', timeZone: '-5/6' },
                    { value: 'tuscaloosa', label: 'Tuscaloosa', timeZone: '-5/6' },
                ],
            },
            {
                value: 'alaska',
                label: 'Alaska',
                children: [
                    { value: 'anchorage', label: 'Anchorage', timeZone: '-8/9' },
                    { value: 'fairbanks', label: 'Fairbanks', timeZone: '-8/9' },
                    { value: 'juneau', label: 'Juneau', timeZone: '-8/9' },
                    { value: 'sitka', label: 'Sitka', timeZone: '-8/9' },
                ],
            },
            {
                value: 'arizona',
                label: 'Arizona',
                children: [
                    { value: 'phoenix', label: 'Phoenix', timeZone: '-7' },
                    { value: 'tucson', label: 'Tucson', timeZone: '-7' },
                    { value: 'mesa', label: 'Mesa', timeZone: '-7' },
                    { value: 'chandler', label: 'Chandler', timeZone: '-7' },
                    { value: 'scottsdale', label: 'Scottsdale', timeZone: '-7' },
                    { value: 'glendale', label: 'Glendale', timeZone: '-7' },
                ],
            },
            {
                value: 'arkansas',
                label: 'Arkansas',
                children: [
                    { value: 'little_rock', label: 'Little Rock', timeZone: '-5/6' },
                    { value: 'fort_smith', label: 'Fort Smith', timeZone: '-5/6' },
                    { value: 'fayetteville', label: 'Fayetteville', timeZone: '-5/6' },
                    { value: 'springdale', label: 'Springdale', timeZone: '-5/6' },
                ],
            },
            {
                value: 'california',
                label: 'California',
                children: [
                    { value: 'los_angeles', label: 'Los Angeles', timeZone: '-7/8' },
                    { value: 'san_diego', label: 'San Diego', timeZone: '-7/8' },
                    { value: 'san_jose', label: 'San Jose', timeZone: '-7/8' },
                    { value: 'san_francisco', label: 'San Francisco', timeZone: '-7/8' },
                    { value: 'fresno', label: 'Fresno', timeZone: '-7/8' },
                    { value: 'sacramento', label: 'Sacramento', timeZone: '-7/8' },
                    { value: 'long_beach', label: 'Long Beach', timeZone: '-7/8' },
                    { value: 'oakland', label: 'Oakland', timeZone: '-7/8' },
                    { value: 'bakersfield', label: 'Bakersfield', timeZone: '-7/8' },
                    { value: 'anaheim', label: 'Anaheim', timeZone: '-7/8' },
                ],
            },
            {
                value: 'colorado',
                label: 'Colorado',
                children: [
                    { value: 'denver', label: 'Denver', timeZone: '-6/7' },
                    { value: 'colorado_springs', label: 'Colorado Springs', timeZone: '-6/7' },
                    { value: 'aurora', label: 'Aurora', timeZone: '-6/7' },
                    { value: 'fort_collins', label: 'Fort Collins', timeZone: '-6/7' },
                    { value: 'lakewood', label: 'Lakewood', timeZone: '-6/7' },
                ],
            },
            {
                value: 'connecticut',
                label: 'Connecticut',
                children: [
                    { value: 'bridgeport', label: 'Bridgeport', timeZone: '-4/5' },
                    { value: 'new_haven', label: 'New Haven', timeZone: '-4/5' },
                    { value: 'hartford', label: 'Hartford', timeZone: '-4/5' },
                    { value: 'stamford', label: 'Stamford', timeZone: '-4/5' },
                    { value: 'waterbury', label: 'Waterbury', timeZone: '-4/5' },
                ],
            },
            {
                value: 'delaware',
                label: 'Delaware',
                children: [
                    { value: 'wilmington', label: 'Wilmington', timeZone: '-4/5' },
                    { value: 'dover', label: 'Dover', timeZone: '-4/5' },
                    { value: 'newark', label: 'Newark', timeZone: '-4/5' },
                ],
            },
            {
                value: 'florida',
                label: 'Florida',
                children: [
                    { value: 'jacksonville', label: 'Jacksonville', timeZone: '-4/5' },
                    { value: 'miami', label: 'Miami', timeZone: '-4/5' },
                    { value: 'tampa', label: 'Tampa', timeZone: '-4/5' },
                    { value: 'orlando', label: 'Orlando', timeZone: '-4/5' },
                    { value: 'st_petersburg', label: 'St. Petersburg', timeZone: '-4/5' },
                    { value: 'hialeah', label: 'Hialeah', timeZone: '-4/5' },
                    { value: 'tallahassee', label: 'Tallahassee', timeZone: '-4/5' },
                    { value: 'fort_lauderdale', label: 'Fort Lauderdale', timeZone: '-4/5' },
                ],
            },
            {
                value: 'georgia',
                label: 'Georgia',
                children: [
                    { value: 'atlanta', label: 'Atlanta', timeZone: '-4/5' },
                    { value: 'augusta', label: 'Augusta', timeZone: '-4/5' },
                    { value: 'columbus', label: 'Columbus', timeZone: '-4/5' },
                    { value: 'macon', label: 'Macon', timeZone: '-4/5' },
                    { value: 'savannah', label: 'Savannah', timeZone: '-4/5' },
                    { value: 'athens', label: 'Athens', timeZone: '-4/5' },
                ],
            },
            {
                value: 'hawaii',
                label: 'Hawaii',
                children: [
                    { value: 'honolulu', label: 'Honolulu', timeZone: '-10' },
                    { value: 'east_honolulu', label: 'East Honolulu', timeZone: '-10' },
                    { value: 'pearl_city', label: 'Pearl City', timeZone: '-10' },
                    { value: 'hilo', label: 'Hilo', timeZone: '-10' },
                ],
            },
            {
                value: 'idaho',
                label: 'Idaho',
                children: [
                    { value: 'boise', label: 'Boise', timeZone: '-6/7' },
                    { value: 'meridian', label: 'Meridian', timeZone: '-6/7' },
                    { value: 'nampa', label: 'Nampa', timeZone: '-6/7' },
                    { value: 'idaho_falls', label: 'Idaho Falls', timeZone: '-6/7' },
                ],
            },
            {
                value: 'illinois',
                label: 'Illinois',
                children: [
                    { value: 'chicago', label: 'Chicago', timeZone: '-5/6' },
                    { value: 'aurora', label: 'Aurora', timeZone: '-5/6' },
                    { value: 'rockford', label: 'Rockford', timeZone: '-5/6' },
                    { value: 'joliet', label: 'Joliet', timeZone: '-5/6' },
                    { value: 'naperville', label: 'Naperville', timeZone: '-5/6' },
                    { value: 'springfield', label: 'Springfield', timeZone: '-5/6' },
                ],
            },
            {
                value: 'indiana',
                label: 'Indiana',
                children: [
                    { value: 'indianapolis', label: 'Indianapolis', timeZone: '-4/5' },
                    { value: 'fort_wayne', label: 'Fort Wayne', timeZone: '-4/5' },
                    { value: 'evansville', label: 'Evansville', timeZone: '-4/5' },
                    { value: 'south_bend', label: 'South Bend', timeZone: '-4/5' },
                    { value: 'carmel', label: 'Carmel', timeZone: '-4/5' },
                ],
            },
            {
                value: 'iowa',
                label: 'Iowa',
                children: [
                    { value: 'des_moines', label: 'Des Moines', timeZone: '-5/6' },
                    { value: 'cedar_rapids', label: 'Cedar Rapids', timeZone: '-5/6' },
                    { value: 'davenport', label: 'Davenport', timeZone: '-5/6' },
                    { value: 'sioux_city', label: 'Sioux City', timeZone: '-5/6' },
                ],
            },
            {
                value: 'kansas',
                label: 'Kansas',
                children: [
                    { value: 'wichita', label: 'Wichita', timeZone: '-5/6' },
                    { value: 'overland_park', label: 'Overland Park', timeZone: '-5/6' },
                    { value: 'kansas_city', label: 'Kansas City', timeZone: '-5/6' },
                    { value: 'olathe', label: 'Olathe', timeZone: '-5/6' },
                    { value: 'topeka', label: 'Topeka', timeZone: '-5/6' },
                ],
            },
            {
                value: 'kentucky',
                label: 'Kentucky',
                children: [
                    { value: 'louisville', label: 'Louisville', timeZone: '-4/5' },
                    { value: 'lexington', label: 'Lexington', timeZone: '-4/5' },
                    { value: 'bowling_green', label: 'Bowling Green', timeZone: '-5/6' },
                    { value: 'owensboro', label: 'Owensboro', timeZone: '-5/6' },
                ],
            },
            {
                value: 'louisiana',
                label: 'Louisiana',
                children: [
                    { value: 'new_orleans', label: 'New Orleans', timeZone: '-5/6' },
                    { value: 'baton_rouge', label: 'Baton Rouge', timeZone: '-5/6' },
                    { value: 'shreveport', label: 'Shreveport', timeZone: '-5/6' },
                    { value: 'lafayette', label: 'Lafayette', timeZone: '-5/6' },
                    { value: 'lake_charles', label: 'Lake Charles', timeZone: '-5/6' },
                ],
            },
            {
                value: 'maine',
                label: 'Maine',
                children: [
                    { value: 'portland', label: 'Portland', timeZone: '-4/5' },
                    { value: 'lewiston', label: 'Lewiston', timeZone: '-4/5' },
                    { value: 'bangor', label: 'Bangor', timeZone: '-4/5' },
                ],
            },
            {
                value: 'maryland',
                label: 'Maryland',
                children: [
                    { value: 'baltimore', label: 'Baltimore', timeZone: '-4/5' },
                    { value: 'columbia', label: 'Columbia', timeZone: '-4/5' },
                    { value: 'germantown', label: 'Germantown', timeZone: '-4/5' },
                    { value: 'silver_spring', label: 'Silver Spring', timeZone: '-4/5' },
                    { value: 'annapolis', label: 'Annapolis', timeZone: '-4/5' },
                ],
            },
            {
                value: 'massachusetts',
                label: 'Massachusetts',
                children: [
                    { value: 'boston', label: 'Boston', timeZone: '-4/5' },
                    { value: 'worcester', label: 'Worcester', timeZone: '-4/5' },
                    { value: 'springfield', label: 'Springfield', timeZone: '-4/5' },
                    { value: 'cambridge', label: 'Cambridge', timeZone: '-4/5' },
                    { value: 'lowell', label: 'Lowell', timeZone: '-4/5' },
                ],
            },
            {
                value: 'michigan',
                label: 'Michigan',
                children: [
                    { value: 'detroit', label: 'Detroit', timeZone: '-4/5' },
                    { value: 'grand_rapids', label: 'Grand Rapids', timeZone: '-4/5' },
                    { value: 'warren', label: 'Warren', timeZone: '-4/5' },
                    { value: 'sterling_heights', label: 'Sterling Heights', timeZone: '-4/5' },
                    { value: 'lansing', label: 'Lansing', timeZone: '-4/5' },
                    { value: 'ann_arbor', label: 'Ann Arbor', timeZone: '-4/5' },
                ],
            },
            {
                value: 'minnesota',
                label: 'Minnesota',
                children: [
                    { value: 'minneapolis', label: 'Minneapolis', timeZone: '-5/6' },
                    { value: 'saint_paul', label: 'Saint Paul', timeZone: '-5/6' },
                    { value: 'rochester', label: 'Rochester', timeZone: '-5/6' },
                    { value: 'duluth', label: 'Duluth', timeZone: '-5/6' },
                    { value: 'bloomington', label: 'Bloomington', timeZone: '-5/6' },
                ],
            },
            {
                value: 'mississippi',
                label: 'Mississippi',
                children: [
                    { value: 'jackson', label: 'Jackson', timeZone: '-5/6' },
                    { value: 'gulfport', label: 'Gulfport', timeZone: '-5/6' },
                    { value: 'southaven', label: 'Southaven', timeZone: '-5/6' },
                    { value: 'biloxi', label: 'Biloxi', timeZone: '-5/6' },
                    { value: 'hattiesburg', label: 'Hattiesburg', timeZone: '-5/6' },
                ],
            },
            {
                value: 'missouri',
                label: 'Missouri',
                children: [
                    { value: 'kansas_city', label: 'Kansas City', timeZone: '-5/6' },
                    { value: 'saint_louis', label: 'Saint Louis', timeZone: '-5/6' },
                    { value: 'springfield', label: 'Springfield', timeZone: '-5/6' },
                    { value: 'columbia', label: 'Columbia', timeZone: '-5/6' },
                    { value: 'independence', label: 'Independence', timeZone: '-5/6' },
                ],
            },
            {
                value: 'montana',
                label: 'Montana',
                children: [
                    { value: 'billings', label: 'Billings', timeZone: '-6/7' },
                    { value: 'missoula', label: 'Missoula', timeZone: '-6/7' },
                    { value: 'great_falls', label: 'Great Falls', timeZone: '-6/7' },
                    { value: 'bozeman', label: 'Bozeman', timeZone: '-6/7' },
                ],
            },
            {
                value: 'nebraska',
                label: 'Nebraska',
                children: [
                    { value: 'omaha', label: 'Omaha', timeZone: '-5/6' },
                    { value: 'lincoln', label: 'Lincoln', timeZone: '-5/6' },
                    { value: 'bellevue', label: 'Bellevue', timeZone: '-5/6' },
                    { value: 'grand_island', label: 'Grand Island', timeZone: '-5/6' },
                ],
            },
            {
                value: 'nevada',
                label: 'Nevada',
                children: [
                    { value: 'las_vegas', label: 'Las Vegas', timeZone: '-7/8' },
                    { value: 'henderson', label: 'Henderson', timeZone: '-7/8' },
                    { value: 'reno', label: 'Reno', timeZone: '-7/8' },
                    { value: 'north_las_vegas', label: 'North Las Vegas', timeZone: '-7/8' },
                    { value: 'sparks', label: 'Sparks', timeZone: '-7/8' },
                ],
            },
            {
                value: 'new_hampshire',
                label: 'New Hampshire',
                children: [
                    { value: 'manchester', label: 'Manchester', timeZone: '-4/5' },
                    { value: 'nashua', label: 'Nashua', timeZone: '-4/5' },
                    { value: 'concord', label: 'Concord', timeZone: '-4/5' },
                    { value: 'dover', label: 'Dover', timeZone: '-4/5' },
                ],
            },
            {
                value: 'new_jersey',
                label: 'New Jersey',
                children: [
                    { value: 'newark', label: 'Newark', timeZone: '-4/5' },
                    { value: 'jersey_city', label: 'Jersey City', timeZone: '-4/5' },
                    { value: 'paterson', label: 'Paterson', timeZone: '-4/5' },
                    { value: 'elizabeth', label: 'Elizabeth', timeZone: '-4/5' },
                    { value: 'trenton', label: 'Trenton', timeZone: '-4/5' },
                    { value: 'camden', label: 'Camden', timeZone: '-4/5' },
                ],
            },
            {
                value: 'new_mexico',
                label: 'New Mexico',
                children: [
                    { value: 'albuquerque', label: 'Albuquerque', timeZone: '-6/7' },
                    { value: 'las_cruces', label: 'Las Cruces', timeZone: '-6/7' },
                    { value: 'rio_rancho', label: 'Rio Rancho', timeZone: '-6/7' },
                    { value: 'santa_fe', label: 'Santa Fe', timeZone: '-6/7' },
                ],
            },
            {
                value: 'new_york',
                label: 'New York',
                children: [
                    { value: 'new_york_city', label: 'New York City', timeZone: '-4/5' },
                    { value: 'buffalo', label: 'Buffalo', timeZone: '-4/5' },
                    { value: 'rochester', label: 'Rochester', timeZone: '-4/5' },
                    { value: 'yonkers', label: 'Yonkers', timeZone: '-4/5' },
                    { value: 'syracuse', label: 'Syracuse', timeZone: '-4/5' },
                    { value: 'albany', label: 'Albany', timeZone: '-4/5' },
                    { value: 'new_rochelle', label: 'New Rochelle', timeZone: '-4/5' },
                    { value: 'mount_vernon', label: 'Mount Vernon', timeZone: '-4/5' },
                ],
            },
            {
                value: 'north_carolina',
                label: 'North Carolina',
                children: [
                    { value: 'charlotte', label: 'Charlotte', timeZone: '-4/5' },
                    { value: 'raleigh', label: 'Raleigh', timeZone: '-4/5' },
                    { value: 'greensboro', label: 'Greensboro', timeZone: '-4/5' },
                    { value: 'durham', label: 'Durham', timeZone: '-4/5' },
                    { value: 'winston_salem', label: 'Winston-Salem', timeZone: '-4/5' },
                    { value: 'fayetteville', label: 'Fayetteville', timeZone: '-4/5' },
                ],
            },
            {
                value: 'north_dakota',
                label: 'North Dakota',
                children: [
                    { value: 'fargo', label: 'Fargo', timeZone: '-5/6' },
                    { value: 'bismarck', label: 'Bismarck', timeZone: '-5/6' },
                    { value: 'grand_forks', label: 'Grand Forks', timeZone: '-5/6' },
                    { value: 'minot', label: 'Minot', timeZone: '-5/6' },
                ],
            },
            {
                value: 'ohio',
                label: 'Ohio',
                children: [
                    { value: 'columbus', label: 'Columbus', timeZone: '-4/5' },
                    { value: 'cleveland', label: 'Cleveland', timeZone: '-4/5' },
                    { value: 'cincinnati', label: 'Cincinnati', timeZone: '-4/5' },
                    { value: 'toledo', label: 'Toledo', timeZone: '-4/5' },
                    { value: 'akron', label: 'Akron', timeZone: '-4/5' },
                    { value: 'dayton', label: 'Dayton', timeZone: '-4/5' },
                ],
            },
            {
                value: 'oklahoma',
                label: 'Oklahoma',
                children: [
                    { value: 'oklahoma_city', label: 'Oklahoma City', timeZone: '-5/6' },
                    { value: 'tulsa', label: 'Tulsa', timeZone: '-5/6' },
                    { value: 'norman', label: 'Norman', timeZone: '-5/6' },
                    { value: 'broken_arrow', label: 'Broken Arrow', timeZone: '-5/6' },
                    { value: 'edmond', label: 'Edmond', timeZone: '-5/6' },
                ],
            },
            {
                value: 'oregon',
                label: 'Oregon',
                children: [
                    { value: 'portland', label: 'Portland', timeZone: '-7/8' },
                    { value: 'eugene', label: 'Eugene', timeZone: '-7/8' },
                    { value: 'salem', label: 'Salem', timeZone: '-7/8' },
                    { value: 'gresham', label: 'Gresham', timeZone: '-7/8' },
                    { value: 'hillsboro', label: 'Hillsboro', timeZone: '-7/8' },
                ],
            },
            {
                value: 'pennsylvania',
                label: 'Pennsylvania',
                children: [
                    { value: 'philadelphia', label: 'Philadelphia', timeZone: '-4/5' },
                    { value: 'pittsburgh', label: 'Pittsburgh', timeZone: '-4/5' },
                    { value: 'allentown', label: 'Allentown', timeZone: '-4/5' },
                    { value: 'erie', label: 'Erie', timeZone: '-4/5' },
                    { value: 'reading', label: 'Reading', timeZone: '-4/5' },
                    { value: 'scranton', label: 'Scranton', timeZone: '-4/5' },
                ],
            },
            {
                value: 'rhode_island',
                label: 'Rhode Island',
                children: [
                    { value: 'providence', label: 'Providence', timeZone: '-4/5' },
                    { value: 'warwick', label: 'Warwick', timeZone: '-4/5' },
                    { value: 'cranston', label: 'Cranston', timeZone: '-4/5' },
                    { value: 'pawtucket', label: 'Pawtucket', timeZone: '-4/5' },
                ],
            },
            {
                value: 'south_carolina',
                label: 'South Carolina',
                children: [
                    { value: 'columbia', label: 'Columbia', timeZone: '-4/5' },
                    { value: 'charleston', label: 'Charleston', timeZone: '-4/5' },
                    { value: 'north_charleston', label: 'North Charleston', timeZone: '-4/5' },
                    { value: 'mount_pleasant', label: 'Mount Pleasant', timeZone: '-4/5' },
                    { value: 'greenville', label: 'Greenville', timeZone: '-4/5' },
                ],
            },
            {
                value: 'south_dakota',
                label: 'South Dakota',
                children: [
                    { value: 'sioux_falls', label: 'Sioux Falls', timeZone: '-5/6' },
                    { value: 'rapid_city', label: 'Rapid City', timeZone: '-6/7' },
                    { value: 'aberdeen', label: 'Aberdeen', timeZone: '-5/6' },
                    { value: 'brookings', label: 'Brookings', timeZone: '-5/6' },
                ],
            },
            {
                value: 'tennessee',
                label: 'Tennessee',
                children: [
                    { value: 'nashville', label: 'Nashville', timeZone: '-5/6' },
                    { value: 'memphis', label: 'Memphis', timeZone: '-5/6' },
                    { value: 'knoxville', label: 'Knoxville', timeZone: '-4/5' },
                    { value: 'chattanooga', label: 'Chattanooga', timeZone: '-4/5' },
                    { value: 'clarksville', label: 'Clarksville', timeZone: '-5/6' },
                ],
            },
            {
                value: 'texas',
                label: 'Texas',
                children: [
                    { value: 'houston', label: 'Houston', timeZone: '-5/6' },
                    { value: 'san_antonio', label: 'San Antonio', timeZone: '-5/6' },
                    { value: 'dallas', label: 'Dallas', timeZone: '-5/6' },
                    { value: 'austin', label: 'Austin', timeZone: '-5/6' },
                    { value: 'fort_worth', label: 'Fort Worth', timeZone: '-5/6' },
                    { value: 'el_paso', label: 'El Paso', timeZone: '-6/7' },
                    { value: 'arlington', label: 'Arlington', timeZone: '-5/6' },
                    { value: 'corpus_christi', label: 'Corpus Christi', timeZone: '-5/6' },
                    { value: 'plano', label: 'Plano', timeZone: '-5/6' },
                    { value: 'laredo', label: 'Laredo', timeZone: '-5/6' },
                ],
            },
            {
                value: 'utah',
                label: 'Utah',
                children: [
                    { value: 'salt_lake_city', label: 'Salt Lake City', timeZone: '-6/7' },
                    { value: 'west_valley_city', label: 'West Valley City', timeZone: '-6/7' },
                    { value: 'provo', label: 'Provo', timeZone: '-6/7' },
                    { value: 'west_jordan', label: 'West Jordan', timeZone: '-6/7' },
                    { value: 'orem', label: 'Orem', timeZone: '-6/7' },
                ],
            },
            {
                value: 'vermont',
                label: 'Vermont',
                children: [
                    { value: 'burlington', label: 'Burlington', timeZone: '-4/5' },
                    { value: 'south_burlington', label: 'South Burlington', timeZone: '-4/5' },
                    { value: 'rutland', label: 'Rutland', timeZone: '-4/5' },
                ],
            },
            {
                value: 'virginia',
                label: 'Virginia',
                children: [
                    { value: 'virginia_beach', label: 'Virginia Beach', timeZone: '-4/5' },
                    { value: 'norfolk', label: 'Norfolk', timeZone: '-4/5' },
                    { value: 'chesapeake', label: 'Chesapeake', timeZone: '-4/5' },
                    { value: 'richmond', label: 'Richmond', timeZone: '-4/5' },
                    { value: 'newport_news', label: 'Newport News', timeZone: '-4/5' },
                    { value: 'alexandria', label: 'Alexandria', timeZone: '-4/5' },
                    { value: 'roanoke', label: 'Roanoke', timeZone: '-4/5' },
                    { value: 'lynchburg', label: 'Lynchburg', timeZone: '-4/5' },
                ],
            },
            {
                value: 'washington',
                label: 'Washington',
                children: [
                    { value: 'seattle', label: 'Seattle', timeZone: '-7/8' },
                    { value: 'spokane', label: 'Spokane', timeZone: '-7/8' },
                    { value: 'tacoma', label: 'Tacoma', timeZone: '-7/8' },
                    { value: 'vancouver', label: 'Vancouver', timeZone: '-7/8' },
                    { value: 'bellevue', label: 'Bellevue', timeZone: '-7/8' },
                    { value: 'kent', label: 'Kent', timeZone: '-7/8' },
                    { value: 'everett', label: 'Everett', timeZone: '-7/8' },
                ],
            },
            {
                value: 'west_virginia',
                label: 'West Virginia',
                children: [
                    { value: 'charleston', label: 'Charleston', timeZone: '-4/5' },
                    { value: 'huntington', label: 'Huntington', timeZone: '-4/5' },
                    { value: 'morgantown', label: 'Morgantown', timeZone: '-4/5' },
                    { value: 'parkersburg', label: 'Parkersburg', timeZone: '-4/5' },
                    { value: 'wheeling', label: 'Wheeling', timeZone: '-4/5' },
                ],
            },
            {
                value: 'wisconsin',
                label: 'Wisconsin',
                children: [
                    { value: 'milwaukee', label: 'Milwaukee', timeZone: '-5/6' },
                    { value: 'madison', label: 'Madison', timeZone: '-5/6' },
                    { value: 'green_bay', label: 'Green Bay', timeZone: '-5/6' },
                    { value: 'kenosha', label: 'Kenosha', timeZone: '-5/6' },
                    { value: 'racine', label: 'Racine', timeZone: '-5/6' },
                    { value: 'appleton', label: 'Appleton', timeZone: '-5/6' },
                ],
            },
            {
                value: 'wyoming',
                label: 'Wyoming',
                children: [
                    { value: 'cheyenne', label: 'Cheyenne', timeZone: '-6/7' },
                    { value: 'casper', label: 'Casper', timeZone: '-6/7' },
                    { value: 'laramie', label: 'Laramie', timeZone: '-6/7' },
                    { value: 'gillette', label: 'Gillette', timeZone: '-6/7' },
                ],
            },
        ],
    },
    {
        value: 'mexico',
        label: 'Mexico',
        children: [
            { value: 'aguascalientes', label: 'Aguascalientes', timeZone: '-6' },
            { value: 'baja_california', label: 'Baja California', timeZone: '-7/8' },
            { value: 'baja_california_sur', label: 'Baja California Sur', timeZone: '-7' },
            { value: 'campeche', label: 'Campeche', timeZone: '-6' },
            { value: 'chiapas', label: 'Chiapas', timeZone: '-6' },
            { value: 'chihuahua', label: 'Chihuahua', timeZone: '-7' },
            { value: 'coahuila', label: 'Coahuila', timeZone: '-6' },
            { value: 'colima', label: 'Colima', timeZone: '-6' },
            { value: 'durango', label: 'Durango', timeZone: '-6' },
            { value: 'guanajuato', label: 'Guanajuato', timeZone: '-6' },
            { value: 'guerrero', label: 'Guerrero', timeZone: '-6' },
            { value: 'hidalgo', label: 'Hidalgo', timeZone: '-6' },
            { value: 'jalisco', label: 'Jalisco', timeZone: '-6' },
            { value: 'mexico_state', label: 'México State', timeZone: '-6' },
            { value: 'mexico_city', label: 'Mexico City', timeZone: '-6' },
            { value: 'michoacan', label: 'Michoacán', timeZone: '-6' },
            { value: 'morelos', label: 'Morelos', timeZone: '-6' },
            { value: 'nayarit', label: 'Nayarit', timeZone: '-7' },
            { value: 'nuevo_leon', label: 'Nuevo León', timeZone: '-6' },
            { value: 'oaxaca', label: 'Oaxaca', timeZone: '-6' },
            { value: 'puebla', label: 'Puebla', timeZone: '-6' },
            { value: 'queretaro', label: 'Querétaro', timeZone: '-6' },
            { value: 'quintana_roo', label: 'Quintana Roo', timeZone: '-5' },
            { value: 'san_luis_potosi', label: 'San Luis Potosí', timeZone: '-6' },
            { value: 'sinaloa', label: 'Sinaloa', timeZone: '-7' },
            { value: 'sonora', label: 'Sonora', timeZone: '-7' }, // Doesn't observe DST
            { value: 'tabasco', label: 'Tabasco', timeZone: '-6' },
            { value: 'tamaulipas', label: 'Tamaulipas', timeZone: '-6' },
            { value: 'tlaxcala', label: 'Tlaxcala', timeZone: '-6' },
            { value: 'veracruz', label: 'Veracruz', timeZone: '-6' },
            { value: 'yucatan', label: 'Yucatán', timeZone: '-6' },
            { value: 'zacatecas', label: 'Zacatecas', timeZone: '-6' },
        ],
    },
    {
        value: 'canada',
        label: 'Canada',
        children: [
            {
                value: 'alberta',
                label: 'Alberta',
                children: [
                    { value: 'calgary', label: 'Calgary', timeZone: '-6/7' },
                    { value: 'edmonton', label: 'Edmonton', timeZone: '-6/7' },
                    { value: 'red_deer', label: 'Red Deer', timeZone: '-6/7' },
                    { value: 'lethbridge', label: 'Lethbridge', timeZone: '-6/7' },
                    { value: 'medicine_hat', label: 'Medicine Hat', timeZone: '-6/7' },
                    { value: 'fort_mcmurray', label: 'Fort McMurray', timeZone: '-6/7' },
                ],
            },
            {
                value: 'british_columbia',
                label: 'British Columbia',
                children: [
                    { value: 'vancouver', label: 'Vancouver', timeZone: '-7/8' },
                    { value: 'victoria', label: 'Victoria', timeZone: '-7/8' },
                    { value: 'surrey', label: 'Surrey', timeZone: '-7/8' },
                    { value: 'burnaby', label: 'Burnaby', timeZone: '-7/8' },
                    { value: 'kelowna', label: 'Kelowna', timeZone: '-7/8' },
                    { value: 'richmond', label: 'Richmond', timeZone: '-7/8' },
                    { value: 'kamloops', label: 'Kamloops', timeZone: '-7/8' },
                    { value: 'nanaimo', label: 'Nanaimo', timeZone: '-7/8' },
                ],
            },
            {
                value: 'manitoba',
                label: 'Manitoba',
                children: [
                    { value: 'winnipeg', label: 'Winnipeg', timeZone: '-5/6' },
                    { value: 'brandon', label: 'Brandon', timeZone: '-5/6' },
                    { value: 'steinbach', label: 'Steinbach', timeZone: '-5/6' },
                    { value: 'thompson', label: 'Thompson', timeZone: '-5/6' },
                    { value: 'portage_la_prairie', label: 'Portage la Prairie', timeZone: '-5/6' },
                ],
            },
            {
                value: 'new_brunswick',
                label: 'New Brunswick',
                children: [
                    { value: 'saint_john', label: 'Saint John', timeZone: '-3/4' },
                    { value: 'moncton', label: 'Moncton', timeZone: '-3/4' },
                    { value: 'fredericton', label: 'Fredericton', timeZone: '-3/4' },
                    { value: 'miramichi', label: 'Miramichi', timeZone: '-3/4' },
                    { value: 'bathurst', label: 'Bathurst', timeZone: '-3/4' },
                ],
            },
            {
                value: 'newfoundland_and_labrador',
                label: 'Newfoundland and Labrador',
                children: [
                    { value: 'st_johns', label: "St. John's", timeZone: '-2.5/3.5' },
                    { value: 'mount_pearl', label: 'Mount Pearl', timeZone: '-2.5/3.5' },
                    { value: 'corner_brook', label: 'Corner Brook', timeZone: '-2.5/3.5' },
                    { value: 'grand_falls_windsor', label: 'Grand Falls-Windsor', timeZone: '-2.5/3.5' },
                    { value: 'labrador_city', label: 'Labrador City', timeZone: '-3/4' },
                ],
            },
            {
                value: 'northwest_territories',
                label: 'Northwest Territories',
                children: [
                    { value: 'yellowknife', label: 'Yellowknife', timeZone: '-6/7' },
                    { value: 'hay_river', label: 'Hay River', timeZone: '-6/7' },
                    { value: 'inuvik', label: 'Inuvik', timeZone: '-6/7' },
                ],
            },
            {
                value: 'nova_scotia',
                label: 'Nova Scotia',
                children: [
                    { value: 'halifax', label: 'Halifax', timeZone: '-3/4' },
                    { value: 'dartmouth', label: 'Dartmouth', timeZone: '-3/4' },
                    { value: 'sydney', label: 'Sydney', timeZone: '-3/4' },
                    { value: 'truro', label: 'Truro', timeZone: '-3/4' },
                    { value: 'bridgewater', label: 'Bridgewater', timeZone: '-3/4' },
                ],
            },
            {
                value: 'nunavut',
                label: 'Nunavut',
                children: [
                    { value: 'iqaluit', label: 'Iqaluit', timeZone: '-4/5' },
                    { value: 'rankin_inlet', label: 'Rankin Inlet', timeZone: '-5/6' },
                    { value: 'cambridge_bay', label: 'Cambridge Bay', timeZone: '-6/7' },
                ],
            },
            {
                value: 'ontario',
                label: 'Ontario',
                children: [
                    { value: 'toronto', label: 'Toronto', timeZone: '-4/5' },
                    { value: 'ottawa', label: 'Ottawa', timeZone: '-4/5' },
                    { value: 'mississauga', label: 'Mississauga', timeZone: '-4/5' },
                    { value: 'brampton', label: 'Brampton', timeZone: '-4/5' },
                    { value: 'hamilton', label: 'Hamilton', timeZone: '-4/5' },
                    { value: 'london', label: 'London', timeZone: '-4/5' },
                    { value: 'markham', label: 'Markham', timeZone: '-4/5' },
                    { value: 'vaughan', label: 'Vaughan', timeZone: '-4/5' },
                    { value: 'kitchener', label: 'Kitchener', timeZone: '-4/5' },
                    { value: 'windsor', label: 'Windsor', timeZone: '-4/5' },
                    { value: 'thunder_bay', label: 'Thunder Bay', timeZone: '-4/5' },
                    { value: 'sudbury', label: 'Sudbury', timeZone: '-4/5' },
                ],
            },
            {
                value: 'prince_edward_island',
                label: 'Prince Edward Island',
                children: [
                    { value: 'charlottetown', label: 'Charlottetown', timeZone: '-3/4' },
                    { value: 'summerside', label: 'Summerside', timeZone: '-3/4' },
                    { value: 'stratford', label: 'Stratford', timeZone: '-3/4' },
                ],
            },
            {
                value: 'quebec',
                label: 'Quebec',
                children: [
                    { value: 'montreal', label: 'Montreal', timeZone: '-4/5' },
                    { value: 'quebec_city', label: 'Quebec City', timeZone: '-4/5' },
                    { value: 'laval', label: 'Laval', timeZone: '-4/5' },
                    { value: 'gatineau', label: 'Gatineau', timeZone: '-4/5' },
                    { value: 'longueuil', label: 'Longueuil', timeZone: '-4/5' },
                    { value: 'sherbrooke', label: 'Sherbrooke', timeZone: '-4/5' },
                    { value: 'saguenay', label: 'Saguenay', timeZone: '-4/5' },
                    { value: 'trois_rivieres', label: 'Trois-Rivières', timeZone: '-4/5' },
                    { value: 'levis', label: 'Lévis', timeZone: '-4/5' },
                ],
            },
            {
                value: 'saskatchewan',
                label: 'Saskatchewan',
                children: [
                    { value: 'saskatoon', label: 'Saskatoon', timeZone: '-6' },
                    { value: 'regina', label: 'Regina', timeZone: '-6' },
                    { value: 'prince_albert', label: 'Prince Albert', timeZone: '-6' },
                    { value: 'moose_jaw', label: 'Moose Jaw', timeZone: '-6' },
                    { value: 'swift_current', label: 'Swift Current', timeZone: '-6' },
                ],
            },
            {
                value: 'yukon',
                label: 'Yukon',
                children: [
                    { value: 'whitehorse', label: 'Whitehorse', timeZone: '-7' },
                    { value: 'dawson_city', label: 'Dawson City', timeZone: '-7' },
                    { value: 'watson_lake', label: 'Watson Lake', timeZone: '-7' },
                ],
            },
        ],
    },
    {
        value: 'latin_america',
        label: 'Latin America',
        children: [
            {
                value: 'argentina',
                label: 'Argentina',
                children: [
                    { value: 'buenos_aires', label: 'Buenos Aires', timeZone: '-3' },
                    { value: 'catamarca', label: 'Catamarca', timeZone: '-3' },
                    { value: 'chaco', label: 'Chaco', timeZone: '-3' },
                    { value: 'chubut', label: 'Chubut', timeZone: '-3' },
                    { value: 'cordoba', label: 'Córdoba', timeZone: '-3' },
                    { value: 'corrientes', label: 'Corrientes', timeZone: '-3' },
                    { value: 'entre_rios', label: 'Entre Ríos', timeZone: '-3' },
                    { value: 'formosa', label: 'Formosa', timeZone: '-3' },
                    { value: 'jujuy', label: 'Jujuy', timeZone: '-3' },
                    { value: 'la_pampa', label: 'La Pampa', timeZone: '-3' },
                    { value: 'la_rioja', label: 'La Rioja', timeZone: '-3' },
                    { value: 'mendoza', label: 'Mendoza', timeZone: '-3' },
                    { value: 'misiones', label: 'Misiones', timeZone: '-3' },
                    { value: 'neuquen', label: 'Neuquén', timeZone: '-3' },
                    { value: 'rio_negro', label: 'Río Negro', timeZone: '-3' },
                    { value: 'salta', label: 'Salta', timeZone: '-3' },
                    { value: 'san_juan', label: 'San Juan', timeZone: '-3' },
                    { value: 'san_luis', label: 'San Luis', timeZone: '-3' },
                    { value: 'santa_cruz', label: 'Santa Cruz', timeZone: '-3' },
                    { value: 'santa_fe', label: 'Santa Fe', timeZone: '-3' },
                    { value: 'santiago_del_estero', label: 'Santiago del Estero', timeZone: '-3' },
                    { value: 'tierra_del_fuego', label: 'Tierra del Fuego', timeZone: '-3' },
                    { value: 'tucuman', label: 'Tucumán', timeZone: '-3' },
                ],
            },
            {
                value: 'brazil',
                label: 'Brazil',
                children: [
                    { value: 'acre', label: 'Acre', timeZone: '-5' },
                    { value: 'alagoas', label: 'Alagoas', timeZone: '-3' },
                    { value: 'amapa', label: 'Amapá', timeZone: '-3' },
                    { value: 'amazonas', label: 'Amazonas', timeZone: '-4' },
                    { value: 'bahia', label: 'Bahia', timeZone: '-3' },
                    { value: 'ceara', label: 'Ceará', timeZone: '-3' },
                    { value: 'distrito_federal', label: 'Distrito Federal', timeZone: '-3' },
                    { value: 'espirito_santo', label: 'Espírito Santo', timeZone: '-3' },
                    { value: 'goias', label: 'Goiás', timeZone: '-3' },
                    { value: 'maranhao', label: 'Maranhão', timeZone: '-3' },
                    { value: 'mato_grosso', label: 'Mato Grosso', timeZone: '-4' },
                    { value: 'mato_grosso_do_sul', label: 'Mato Grosso do Sul', timeZone: '-4' },
                    { value: 'minas_gerais', label: 'Minas Gerais', timeZone: '-3' },
                    { value: 'para', label: 'Pará', timeZone: '-3' },
                    { value: 'paraiba', label: 'Paraíba', timeZone: '-3' },
                    { value: 'parana', label: 'Paraná', timeZone: '-3' },
                    { value: 'pernambuco', label: 'Pernambuco', timeZone: '-3' },
                    { value: 'piaui', label: 'Piauí', timeZone: '-3' },
                    { value: 'rio_de_janeiro', label: 'Rio de Janeiro', timeZone: '-3' },
                    { value: 'rio_grande_do_norte', label: 'Rio Grande do Norte', timeZone: '-3' },
                    { value: 'rio_grande_do_sul', label: 'Rio Grande do Sul', timeZone: '-3' },
                    { value: 'rondonia', label: 'Rondônia', timeZone: '-4' },
                    { value: 'roraima', label: 'Roraima', timeZone: '-4' },
                    { value: 'santa_catarina', label: 'Santa Catarina', timeZone: '-3' },
                    { value: 'sao_paulo', label: 'São Paulo', timeZone: '-3' },
                    { value: 'sergipe', label: 'Sergipe', timeZone: '-3' },
                    { value: 'tocantins', label: 'Tocantins', timeZone: '-3' },
                ],
            },
            {
                value: 'chile',
                label: 'Chile',
                children: [
                    { value: 'santiago_metro', label: 'Santiago Metropolitan', timeZone: '-3/4' },
                    { value: 'arica', label: 'Arica y Parinacota', timeZone: '-3/4' },
                    { value: 'tarapaca', label: 'Tarapacá', timeZone: '-3/4' },
                    { value: 'antofagasta', label: 'Antofagasta', timeZone: '-3/4' },
                    { value: 'atacama', label: 'Atacama', timeZone: '-3/4' },
                    { value: 'coquimbo', label: 'Coquimbo', timeZone: '-3/4' },
                    { value: 'valparaiso', label: 'Valparaíso', timeZone: '-3/4' },
                    { value: 'ohiggins', label: "O'Higgins", timeZone: '-3/4' },
                    { value: 'maule', label: 'Maule', timeZone: '-3/4' },
                    { value: 'nuble', label: 'Ñuble', timeZone: '-3/4' },
                    { value: 'biobio', label: 'Biobío', timeZone: '-3/4' },
                    { value: 'araucania', label: 'Araucanía', timeZone: '-3/4' },
                    { value: 'los_rios', label: 'Los Ríos', timeZone: '-3/4' },
                    { value: 'los_lagos', label: 'Los Lagos', timeZone: '-3/4' },
                    { value: 'aysen', label: 'Aysén', timeZone: '-3/4' },
                    { value: 'magallanes', label: 'Magallanes', timeZone: '-3' },
                ],
            },
            {
                value: 'colombia',
                label: 'Colombia',
                timeZone: '-5',
            },
            {
                value: 'costa_rica',
                label: 'Costa Rica',
                timeZone: '-6',
            },
            {
                value: 'ecuador',
                label: 'Ecuador',
                children: [
                    { value: 'mainland', label: 'Mainland Ecuador', timeZone: '-5' },
                    { value: 'galapagos', label: 'Galápagos Islands', timeZone: '-6' },
                ],
            },
            {
                value: 'el_salvador',
                label: 'El Salvador',
                timeZone: '-6',
            },
            {
                value: 'guatemala',
                label: 'Guatemala',
                timeZone: '-6',
            },
            {
                value: 'honduras',
                label: 'Honduras',
                timeZone: '-6',
            },
            {
                value: 'nicaragua',
                label: 'Nicaragua',
                timeZone: '-6',
            },
            {
                value: 'panama',
                label: 'Panama',
                timeZone: '-5',
            },
            {
                value: 'paraguay',
                label: 'Paraguay',
                timeZone: '-4',
            },
            {
                value: 'peru',
                label: 'Peru',
                timeZone: '-5',
            },
            {
                value: 'uruguay',
                label: 'Uruguay',
                timeZone: '-3',
            },
            {
                value: 'venezuela',
                label: 'Venezuela',
                timeZone: '-4',
            },
            {
                value: 'french_guiana',
                label: 'French Guiana',
                timeZone: '-3',
            },
            {
                value: 'guyana',
                label: 'Guyana',
                timeZone: '-4',
            },
            {
                value: 'suriname',
                label: 'Suriname',
                timeZone: '-3',
            },
        ],
    },
    {
        value: 'europe',
        label: 'Europe',
        children: [
            {
                value: 'albania',
                label: 'Albania',
                timeZone: '+1/2',
            },
            {
                value: 'andorra',
                label: 'Andorra',
                timeZone: '+1/2',
            },
            {
                value: 'austria',
                label: 'Austria',
                timeZone: '+1/2',
            },
            {
                value: 'belarus',
                label: 'Belarus',
                timeZone: '+3', // Doesn't observe DST
            },
            {
                value: 'belgium',
                label: 'Belgium',
                timeZone: '+1/2',
            },
            {
                value: 'bosnia_herzegovina',
                label: 'Bosnia and Herzegovina',
                timeZone: '+1/2',
            },
            {
                value: 'bulgaria',
                label: 'Bulgaria',
                timeZone: '+2/3',
            },
            {
                value: 'croatia',
                label: 'Croatia',
                timeZone: '+1/2',
            },
            {
                value: 'czech_republic',
                label: 'Czech Republic',
                timeZone: '+1/2',
            },
            {
                value: 'denmark',
                label: 'Denmark',
                children: [
                    { value: 'denmark_mainland', label: 'Denmark Mainland', timeZone: '+1/2' },
                    { value: 'faroe_islands', label: 'Faroe Islands', timeZone: '+0/1' },
                    {
                        value: 'greenland',
                        label: 'Greenland',
                        children: [
                            { value: 'greenland_east', label: 'East Greenland', timeZone: '-1/0' },
                            { value: 'greenland_west', label: 'West Greenland', timeZone: '-3/-2' },
                            { value: 'thule', label: 'Thule Air Base', timeZone: '-4/-3' },
                        ],
                    },
                ],
            },
            {
                value: 'estonia',
                label: 'Estonia',
                timeZone: '+2/3',
            },
            {
                value: 'finland',
                label: 'Finland',
                timeZone: '+2/3',
            },
            {
                value: 'france',
                label: 'France',
                children: [
                    { value: 'mainland_france', label: 'Metropolitan France', timeZone: '+1/2' },
                    { value: 'reunion', label: 'Réunion', timeZone: '+4' },
                    { value: 'mayotte', label: 'Mayotte', timeZone: '+3' },
                    { value: 'french_polynesia', label: 'French Polynesia', timeZone: '-10' },
                    { value: 'guadeloupe', label: 'Guadeloupe', timeZone: '-4' },
                    { value: 'martinique', label: 'Martinique', timeZone: '-4' },
                    { value: 'french_guiana', label: 'French Guiana', timeZone: '-3' },
                    { value: 'new_caledonia', label: 'New Caledonia', timeZone: '+11' },
                ],
            },
            {
                value: 'germany',
                label: 'Germany',
                timeZone: '+1/2',
            },
            {
                value: 'greece',
                label: 'Greece',
                timeZone: '+2/3',
            },
            {
                value: 'hungary',
                label: 'Hungary',
                timeZone: '+1/2',
            },
            {
                value: 'iceland',
                label: 'Iceland',
                timeZone: '+0', // Doesn't observe DST
            },
            {
                value: 'ireland',
                label: 'Ireland',
                timeZone: '+0/1',
            },
            {
                value: 'italy',
                label: 'Italy',
                timeZone: '+1/2',
            },
            {
                value: 'latvia',
                label: 'Latvia',
                timeZone: '+2/3',
            },
            {
                value: 'liechtenstein',
                label: 'Liechtenstein',
                timeZone: '+1/2',
            },
            {
                value: 'lithuania',
                label: 'Lithuania',
                timeZone: '+2/3',
            },
            {
                value: 'luxembourg',
                label: 'Luxembourg',
                timeZone: '+1/2',
            },
            {
                value: 'malta',
                label: 'Malta',
                timeZone: '+1/2',
            },
            {
                value: 'moldova',
                label: 'Moldova',
                timeZone: '+2/3',
            },
            {
                value: 'monaco',
                label: 'Monaco',
                timeZone: '+1/2',
            },
            {
                value: 'montenegro',
                label: 'Montenegro',
                timeZone: '+1/2',
            },
            {
                value: 'netherlands',
                label: 'Netherlands',
                children: [
                    { value: 'netherlands_mainland', label: 'Netherlands Mainland', timeZone: '+1/2' },
                    { value: 'caribbean_netherlands', label: 'Caribbean Netherlands', timeZone: '-4' },
                ],
            },
            {
                value: 'north_macedonia',
                label: 'North Macedonia',
                timeZone: '+1/2',
            },
            {
                value: 'norway',
                label: 'Norway',
                timeZone: '+1/2',
            },
            {
                value: 'poland',
                label: 'Poland',
                timeZone: '+1/2',
            },
            {
                value: 'portugal',
                label: 'Portugal',
                children: [
                    { value: 'mainland_portugal', label: 'Mainland Portugal', timeZone: '+0/1' },
                    { value: 'azores', label: 'Azores', timeZone: '-1/0' },
                    { value: 'madeira', label: 'Madeira', timeZone: '+0/1' },
                ],
            },
            {
                value: 'romania',
                label: 'Romania',
                timeZone: '+2/3',
            },
            {
                value: 'russia',
                label: 'Russia',
                children: [
                    { value: 'kaliningrad', label: 'Kaliningrad Oblast', timeZone: '+2' },
                    { value: 'moscow', label: 'Moscow', timeZone: '+3' },
                    { value: 'samara', label: 'Samara', timeZone: '+4' },
                    { value: 'yekaterinburg', label: 'Yekaterinburg', timeZone: '+5' },
                    { value: 'omsk', label: 'Omsk', timeZone: '+6' },
                    { value: 'krasnoyarsk', label: 'Krasnoyarsk', timeZone: '+7' },
                    { value: 'irkutsk', label: 'Irkutsk', timeZone: '+8' },
                    { value: 'yakutsk', label: 'Yakutsk', timeZone: '+9' },
                    { value: 'vladivostok', label: 'Vladivostok', timeZone: '+10' },
                    { value: 'magadan', label: 'Magadan', timeZone: '+11' },
                    { value: 'kamchatka', label: 'Kamchatka', timeZone: '+12' },
                ],
            },
            {
                value: 'san_marino',
                label: 'San Marino',
                timeZone: '+1/2',
            },
            {
                value: 'serbia',
                label: 'Serbia',
                timeZone: '+1/2',
            },
            {
                value: 'slovakia',
                label: 'Slovakia',
                timeZone: '+1/2',
            },
            {
                value: 'slovenia',
                label: 'Slovenia',
                timeZone: '+1/2',
            },
            {
                value: 'spain',
                label: 'Spain',
                children: [
                    { value: 'mainland_spain', label: 'Mainland Spain', timeZone: '+1/2' },
                    { value: 'canary_islands', label: 'Canary Islands', timeZone: '+0/1' },
                    { value: 'ceuta_melilla', label: 'Ceuta and Melilla', timeZone: '+1/2' },
                ],
            },
            {
                value: 'sweden',
                label: 'Sweden',
                timeZone: '+1/2',
            },
            {
                value: 'switzerland',
                label: 'Switzerland',
                timeZone: '+1/2',
            },
            {
                value: 'ukraine',
                label: 'Ukraine',
                timeZone: '+2/3',
            },
            {
                value: 'united_kingdom',
                label: 'United Kingdom',
                children: [
                    { value: 'main_territory', label: 'Main Territory', timeZone: '+0/1' },
                    { value: 'akrotiri_dhekelia', label: 'Akrotiri and Dhekelia', timeZone: '+2/3' },
                    { value: 'british_indian_ocean', label: 'British Indian Ocean Territory', timeZone: '+6' },
                    { value: 'pitcairn', label: 'Pitcairn Islands', timeZone: '-8' },
                ],
            },
            {
                value: 'vatican_city',
                label: 'Vatican City',
                timeZone: '+1/2',
            },
        ],
    },
    {
        value: 'asia',
        label: 'Asia',
        children: [
            {
                value: 'afghanistan',
                label: 'Afghanistan',
                timeZone: '+4.5',
            },
            {
                value: 'armenia',
                label: 'Armenia',
                timeZone: '+4',
            },
            {
                value: 'azerbaijan',
                label: 'Azerbaijan',
                timeZone: '+4',
            },
            {
                value: 'bahrain',
                label: 'Bahrain',
                timeZone: '+3',
            },
            {
                value: 'bangladesh',
                label: 'Bangladesh',
                timeZone: '+6',
            },
            {
                value: 'bhutan',
                label: 'Bhutan',
                timeZone: '+6',
            },
            {
                value: 'brunei',
                label: 'Brunei',
                timeZone: '+8',
            },
            {
                value: 'cambodia',
                label: 'Cambodia',
                timeZone: '+7',
            },
            {
                value: 'china',
                label: 'China',
                timeZone: '+8',
            },
            {
                value: 'cyprus',
                label: 'Cyprus',
                timeZone: '+2/3',
            },
            {
                value: 'georgia',
                label: 'Georgia',
                timeZone: '+4',
            },
            {
                value: 'india',
                label: 'India',
                timeZone: '+5.5',
            },
            {
                value: 'indonesia',
                label: 'Indonesia',
                children: [
                    { value: 'west_indonesia', label: 'Western Indonesia', timeZone: '+7' },
                    { value: 'central_indonesia', label: 'Central Indonesia', timeZone: '+8' },
                    { value: 'east_indonesia', label: 'Eastern Indonesia', timeZone: '+9' },
                ],
            },
            {
                value: 'iran',
                label: 'Iran',
                timeZone: '+3.5/4.5',
            },
            {
                value: 'iraq',
                label: 'Iraq',
                timeZone: '+3',
            },
            {
                value: 'israel',
                label: 'Israel',
                timeZone: '+2/3',
            },
            {
                value: 'japan',
                label: 'Japan',
                timeZone: '+9',
            },
            {
                value: 'jordan',
                label: 'Jordan',
                timeZone: '+2/3',
            },
            {
                value: 'kazakhstan',
                label: 'Kazakhstan',
                children: [
                    { value: 'west_kazakhstan', label: 'Western Kazakhstan', timeZone: '+5' },
                    { value: 'east_kazakhstan', label: 'Eastern Kazakhstan', timeZone: '+6' },
                ],
            },
            {
                value: 'kuwait',
                label: 'Kuwait',
                timeZone: '+3',
            },
            {
                value: 'kyrgyzstan',
                label: 'Kyrgyzstan',
                timeZone: '+6',
            },
            {
                value: 'laos',
                label: 'Laos',
                timeZone: '+7',
            },
            {
                value: 'lebanon',
                label: 'Lebanon',
                timeZone: '+2/3',
            },
            {
                value: 'malaysia',
                label: 'Malaysia',
                timeZone: '+8',
            },
            {
                value: 'maldives',
                label: 'Maldives',
                timeZone: '+5',
            },
            {
                value: 'mongolia',
                label: 'Mongolia',
                children: [
                    { value: 'mongolia_main', label: 'Main Territory', timeZone: '+8' },
                    { value: 'mongolia_west', label: 'Western Mongolia', timeZone: '+7' },
                ],
            },
            {
                value: 'myanmar',
                label: 'Myanmar',
                timeZone: '+6.5',
            },
            {
                value: 'nepal',
                label: 'Nepal',
                timeZone: '+5.75',
            },
            {
                value: 'north_korea',
                label: 'North Korea',
                timeZone: '+9',
            },
            {
                value: 'oman',
                label: 'Oman',
                timeZone: '+4',
            },
            {
                value: 'pakistan',
                label: 'Pakistan',
                timeZone: '+5',
            },
            {
                value: 'palestine',
                label: 'Palestine',
                timeZone: '+2/3',
            },
            {
                value: 'philippines',
                label: 'Philippines',
                timeZone: '+8',
            },
            {
                value: 'qatar',
                label: 'Qatar',
                timeZone: '+3',
            },
            {
                value: 'saudi_arabia',
                label: 'Saudi Arabia',
                timeZone: '+3',
            },
            {
                value: 'singapore',
                label: 'Singapore',
                timeZone: '+8',
            },
            {
                value: 'south_korea',
                label: 'South Korea',
                timeZone: '+9',
            },
            {
                value: 'sri_lanka',
                label: 'Sri Lanka',
                timeZone: '+5.5',
            },
            {
                value: 'syria',
                label: 'Syria',
                timeZone: '+2/3',
            },
            {
                value: 'taiwan',
                label: 'Taiwan',
                timeZone: '+8',
            },
            {
                value: 'tajikistan',
                label: 'Tajikistan',
                timeZone: '+5',
            },
            {
                value: 'thailand',
                label: 'Thailand',
                timeZone: '+7',
            },
            {
                value: 'timor_leste',
                label: 'Timor-Leste',
                timeZone: '+9',
            },
            {
                value: 'turkey',
                label: 'Turkey',
                timeZone: '+3',
            },
            {
                value: 'turkmenistan',
                label: 'Turkmenistan',
                timeZone: '+5',
            },
            {
                value: 'united_arab_emirates',
                label: 'United Arab Emirates',
                timeZone: '+4',
            },
            {
                value: 'uzbekistan',
                label: 'Uzbekistan',
                timeZone: '+5',
            },
            {
                value: 'vietnam',
                label: 'Vietnam',
                timeZone: '+7',
            },
            {
                value: 'yemen',
                label: 'Yemen',
                timeZone: '+3',
            },
        ],
    },
    {
        value: 'africa',
        label: 'Africa',
        children: [
            {
                value: 'algeria',
                label: 'Algeria',
                timeZone: '+1',
            },
            {
                value: 'angola',
                label: 'Angola',
                timeZone: '+1',
            },
            {
                value: 'benin',
                label: 'Benin',
                timeZone: '+1',
            },
            {
                value: 'botswana',
                label: 'Botswana',
                timeZone: '+2',
            },
            {
                value: 'burkina_faso',
                label: 'Burkina Faso',
                timeZone: '+0',
            },
            {
                value: 'burundi',
                label: 'Burundi',
                timeZone: '+2',
            },
            {
                value: 'cameroon',
                label: 'Cameroon',
                timeZone: '+1',
            },
            {
                value: 'cape_verde',
                label: 'Cape Verde',
                timeZone: '-1',
            },
            {
                value: 'central_african_republic',
                label: 'Central African Republic',
                timeZone: '+1',
            },
            {
                value: 'chad',
                label: 'Chad',
                timeZone: '+1',
            },
            {
                value: 'comoros',
                label: 'Comoros',
                timeZone: '+3',
            },
            {
                value: 'congo',
                label: 'Republic of the Congo',
                timeZone: '+1',
            },
            {
                value: 'congo_drc',
                label: 'DR Congo',
                children: [
                    { value: 'congo_west', label: 'Western DR Congo', timeZone: '+1' },
                    { value: 'congo_east', label: 'Eastern DR Congo', timeZone: '+2' },
                ],
            },
            {
                value: 'djibouti',
                label: 'Djibouti',
                timeZone: '+3',
            },
            {
                value: 'egypt',
                label: 'Egypt',
                timeZone: '+2',
            },
            {
                value: 'equatorial_guinea',
                label: 'Equatorial Guinea',
                timeZone: '+1',
            },
            {
                value: 'eritrea',
                label: 'Eritrea',
                timeZone: '+3',
            },
            {
                value: 'ethiopia',
                label: 'Ethiopia',
                timeZone: '+3',
            },
            {
                value: 'gabon',
                label: 'Gabon',
                timeZone: '+1',
            },
            {
                value: 'gambia',
                label: 'Gambia',
                timeZone: '+0',
            },
            {
                value: 'ghana',
                label: 'Ghana',
                timeZone: '+0',
            },
            {
                value: 'guinea',
                label: 'Guinea',
                timeZone: '+0',
            },
            {
                value: 'guinea_bissau',
                label: 'Guinea-Bissau',
                timeZone: '+0',
            },
            {
                value: 'ivory_coast',
                label: 'Ivory Coast',
                timeZone: '+0',
            },
            {
                value: 'kenya',
                label: 'Kenya',
                timeZone: '+3',
            },
            {
                value: 'lesotho',
                label: 'Lesotho',
                timeZone: '+2',
            },
            {
                value: 'liberia',
                label: 'Liberia',
                timeZone: '+0',
            },
            {
                value: 'libya',
                label: 'Libya',
                timeZone: '+2',
            },
            {
                value: 'madagascar',
                label: 'Madagascar',
                timeZone: '+3',
            },
            {
                value: 'malawi',
                label: 'Malawi',
                timeZone: '+2',
            },
            {
                value: 'mali',
                label: 'Mali',
                timeZone: '+0',
            },
            {
                value: 'mauritania',
                label: 'Mauritania',
                timeZone: '+0',
            },
            {
                value: 'mauritius',
                label: 'Mauritius',
                timeZone: '+4',
            },
            {
                value: 'morocco',
                label: 'Morocco',
                timeZone: '+1',
            },
            {
                value: 'mozambique',
                label: 'Mozambique',
                timeZone: '+2',
            },
            {
                value: 'namibia',
                label: 'Namibia',
                timeZone: '+2',
            },
            {
                value: 'niger',
                label: 'Niger',
                timeZone: '+1',
            },
            {
                value: 'nigeria',
                label: 'Nigeria',
                timeZone: '+1',
            },
            {
                value: 'rwanda',
                label: 'Rwanda',
                timeZone: '+2',
            },
            {
                value: 'sao_tome',
                label: 'São Tomé and Príncipe',
                timeZone: '+0',
            },
            {
                value: 'senegal',
                label: 'Senegal',
                timeZone: '+0',
            },
            {
                value: 'seychelles',
                label: 'Seychelles',
                timeZone: '+4',
            },
            {
                value: 'sierra_leone',
                label: 'Sierra Leone',
                timeZone: '+0',
            },
            {
                value: 'somalia',
                label: 'Somalia',
                timeZone: '+3',
            },
            {
                value: 'south_africa',
                label: 'South Africa',
                timeZone: '+2',
            },
            {
                value: 'south_sudan',
                label: 'South Sudan',
                timeZone: '+3',
            },
            {
                value: 'sudan',
                label: 'Sudan',
                timeZone: '+2',
            },
            {
                value: 'swaziland',
                label: 'Eswatini',
                timeZone: '+2',
            },
            {
                value: 'tanzania',
                label: 'Tanzania',
                timeZone: '+3',
            },
            {
                value: 'togo',
                label: 'Togo',
                timeZone: '+0',
            },
            {
                value: 'tunisia',
                label: 'Tunisia',
                timeZone: '+1',
            },
            {
                value: 'uganda',
                label: 'Uganda',
                timeZone: '+3',
            },
            {
                value: 'zambia',
                label: 'Zambia',
                timeZone: '+2',
            },
            {
                value: 'zimbabwe',
                label: 'Zimbabwe',
                timeZone: '+2',
            },
        ],
    },
    {
        value: 'oceania',
        label: 'Oceania',
        children: [
            {
                value: 'australia',
                label: 'Australia',
                children: [
                    { value: 'western_australia', label: 'Western Australia', timeZone: '+8' },
                    { value: 'northern_territory', label: 'Northern Territory', timeZone: '+9.5' },
                    { value: 'south_australia', label: 'South Australia', timeZone: '+9.5/10.5' },
                    { value: 'queensland', label: 'Queensland', timeZone: '+10' },
                    { value: 'new_south_wales', label: 'New South Wales', timeZone: '+10/11' },
                    { value: 'victoria', label: 'Victoria', timeZone: '+10/11' },
                    { value: 'tasmania', label: 'Tasmania', timeZone: '+10/11' },
                    { value: 'act', label: 'Australian Capital Territory', timeZone: '+10/11' },
                    { value: 'lord_howe', label: 'Lord Howe Island', timeZone: '+10.5/11' },
                ],
            },
            {
                value: 'fiji',
                label: 'Fiji',
                timeZone: '+12/13',
            },
            {
                value: 'kiribati',
                label: 'Kiribati',
                children: [
                    { value: 'gilbert_islands', label: 'Gilbert Islands', timeZone: '+12' },
                    { value: 'phoenix_islands', label: 'Phoenix Islands', timeZone: '+13' },
                    { value: 'line_islands', label: 'Line Islands', timeZone: '+14' },
                ],
            },
            {
                value: 'marshall_islands',
                label: 'Marshall Islands',
                timeZone: '+12',
            },
            {
                value: 'micronesia',
                label: 'Micronesia',
                children: [
                    { value: 'micronesia_western', label: 'Western States', timeZone: '+10' },
                    { value: 'micronesia_eastern', label: 'Eastern States', timeZone: '+11' },
                ],
            },
            {
                value: 'nauru',
                label: 'Nauru',
                timeZone: '+12',
            },
            {
                value: 'new_zealand',
                label: 'New Zealand',
                children: [
                    { value: 'main_islands', label: 'Main Islands', timeZone: '+12/13' },
                    { value: 'chatham_islands', label: 'Chatham Islands', timeZone: '+12.75/13.75' },
                ],
            },
            {
                value: 'palau',
                label: 'Palau',
                timeZone: '+9',
            },
            {
                value: 'papua_new_guinea',
                label: 'Papua New Guinea',
                timeZone: '+10',
            },
            {
                value: 'samoa',
                label: 'Samoa',
                timeZone: '+13/14',
            },
            {
                value: 'solomon_islands',
                label: 'Solomon Islands',
                timeZone: '+11',
            },
            {
                value: 'tonga',
                label: 'Tonga',
                timeZone: '+13/14',
            },
            {
                value: 'tuvalu',
                label: 'Tuvalu',
                timeZone: '+12',
            },
            {
                value: 'vanuatu',
                label: 'Vanuatu',
                timeZone: '+11',
            },
            {
                value: 'american_samoa',
                label: 'American Samoa',
                timeZone: '-11',
            },
            {
                value: 'cook_islands',
                label: 'Cook Islands',
                timeZone: '-10',
            },
            {
                value: 'french_polynesia',
                label: 'French Polynesia',
                children: [
                    { value: 'society_islands', label: 'Society Islands', timeZone: '-10' },
                    { value: 'marquesas_islands', label: 'Marquesas Islands', timeZone: '-9.5' },
                    { value: 'gambier_islands', label: 'Gambier Islands', timeZone: '-9' },
                ],
            },
            {
                value: 'guam',
                label: 'Guam',
                timeZone: '+10',
            },
            {
                value: 'new_caledonia',
                label: 'New Caledonia',
                timeZone: '+11',
            },
            {
                value: 'niue',
                label: 'Niue',
                timeZone: '-11',
            },
            {
                value: 'norfolk_island',
                label: 'Norfolk Island',
                timeZone: '+11/12',
            },
            {
                value: 'northern_mariana_islands',
                label: 'Northern Mariana Islands',
                timeZone: '+10',
            },
            {
                value: 'pitcairn_islands',
                label: 'Pitcairn Islands',
                timeZone: '-8',
            },
            {
                value: 'tokelau',
                label: 'Tokelau',
                timeZone: '+13',
            },
            {
                value: 'wallis_and_futuna',
                label: 'Wallis and Futuna',
                timeZone: '+12',
            },
        ],
    },
]
