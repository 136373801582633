import { useCollectionGroup } from '@tatsuokaniwa/swr-firestore'
import { BrandLibraryTalentData } from '../types'
import { useEffect } from 'react'
import { useApprovedTalentStore } from '../contexts/stores/approvedTalentStore'
import useCollectionGroupClean from './swrFirestore/useCollectionGroupClean'

const useApprovedTalent = (brandId) => {
    const { getApprovedTalentForBrand, setApprovedTalentStore } = useApprovedTalentStore()

    const { data: approvedTalentDb = null, error } = useCollectionGroupClean<BrandLibraryTalentData>(
        brandId
            ? {
                  path: `talentSuggestions`,
                  where: [
                      ['status', '==', 'approved'],
                      ['brand', '==', brandId],
                  ],
              }
            : null,
        {
            keepPreviousData: true,
            revalidateOnFocus: false,
            revalidateOnMount: false,
            revalidateOnReconnect: false,
            dedupingInterval: 1000 * 60 * 5,
        }
    )

    useEffect(() => {
        if (approvedTalentDb && approvedTalentDb.length > 0) {
            setApprovedTalentStore(brandId, approvedTalentDb)
        }
    }, [approvedTalentDb, brandId, setApprovedTalentStore])

    useEffect(() => {
        if (error) {
            console.log(error)
        }
    }, [error])

    return {
        approvedTalent: approvedTalentDb ? approvedTalentDb : getApprovedTalentForBrand(brandId),
    }
}

export default useApprovedTalent
