import { Brands } from '@viscapmedia/viscap-schema-types'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { createIndexedDBStorage } from '../../api/indexed-db/zustandIndexedDBStorage'

interface BrandsStore {
    brands: Brands[]
    pendingBrands: Brands[]
    effectiveBrand: Brands | null
    lastUpdated: number | null
    setBrands: (brands: Brands[], pendingBrands: Brands[]) => void
    updateBrand: (brand: Brands) => void
    setEffectiveBrand: (brand: Brands | null) => void
    clearBrands: () => void
}

const STORAGE_KEY = 'viscap_ai_brands_store'
const CACHE_DURATION = 24 * 60 * 60 * 1000 // 24 hours

export const useBrandsStore = create<BrandsStore>()(
    persist(
        (set) => ({
            brands: [],
            pendingBrands: [],
            effectiveBrand: null,
            lastUpdated: null,
            setBrands: (brands, pendingBrands) =>
                set({
                    brands,
                    pendingBrands,
                    lastUpdated: Date.now(),
                }),
            updateBrand: (brand) =>
                set((state) => ({
                    brands: state.brands.map((b) => (b.id === brand.id ? brand : b)),
                    effectiveBrand: state.effectiveBrand?.id === brand.id ? brand : state.effectiveBrand,
                    lastUpdated: Date.now(),
                })),
            setEffectiveBrand: (brand) =>
                set({
                    effectiveBrand: brand,
                    lastUpdated: Date.now(),
                }),
            clearBrands: () =>
                set({
                    brands: [],
                    pendingBrands: [],
                    effectiveBrand: null,
                    lastUpdated: null,
                }),
        }),
        {
            name: STORAGE_KEY,
            partialize: (state) => ({
                brands: state.brands,
                pendingBrands: state.pendingBrands,
                effectiveBrand: state.effectiveBrand,
                lastUpdated: state.lastUpdated,
            }),
            storage: createIndexedDBStorage(),
        }
    )
)

export const isBrandsCacheValid = (lastUpdated: number | null): boolean => {
    if (!lastUpdated) return false
    return Date.now() - lastUpdated < CACHE_DURATION
}
