import { del, get, set } from 'idb-keyval'
import { PersistStorage, StateStorage, StorageValue } from 'zustand/middleware'

export const zustandIndexedDBStorage: StateStorage = {
    getItem: async (name: string): Promise<string | null> => {
        if (typeof window === 'undefined') return
        return (await get(name)) || null
    },
    setItem: async (name: string, value: string): Promise<void> => {
        if (typeof window === 'undefined') return
        await set(name, value)
    },
    removeItem: async (name: string): Promise<void> => {
        if (typeof window === 'undefined') return
        await del(name)
    },
}

const ZUSTAND_DATABASE_NAME = 'zustand-db'
const ZUSTAND_OBJECT_STORE_NAME = 'zustand-store'
const DEFAULT_DB_VERSION = 1

type IndexedDBStorageOptions = {
    /**
     * The name of the IndexedDB database.
     * @default 'zustand-db'
     */
    name?: string
    /**
     * The version of the IndexedDB database.
     * @default 1
     */
    version?: number
    /**
     * The name of the IndexedDB object store.
     * @default 'zustand-store'
     */
    storeName?: string
}

export function createIndexedDBStorage<S>(options?: IndexedDBStorageOptions): PersistStorage<S> | undefined {
    // Check if IndexedDB is available in the current environment
    if (typeof indexedDB === 'undefined') {
        // console.warn(
        //     '[zustand persist middleware] IndexedDB is not available in this environment. Persisting will not work.'
        // )
        return undefined
    }

    const dbName = options?.name ?? ZUSTAND_DATABASE_NAME
    const dbVersion = options?.version ?? DEFAULT_DB_VERSION
    const storeName = options?.storeName ?? ZUSTAND_OBJECT_STORE_NAME

    let dbPromise: Promise<IDBDatabase> | null = null

    // Function to get or open the database connection
    function getDb(): Promise<IDBDatabase> {
        if (dbPromise) {
            return dbPromise
        }

        dbPromise = new Promise<IDBDatabase>((resolve, reject) => {
            try {
                const request = indexedDB.open(dbName, dbVersion)

                request.onupgradeneeded = (event) => {
                    const db = (event.target as IDBOpenDBRequest).result
                    if (!db.objectStoreNames.contains(storeName)) {
                        db.createObjectStore(storeName)
                    }
                }

                request.onsuccess = (event) => {
                    resolve((event.target as IDBOpenDBRequest).result)
                }

                request.onerror = (event) => {
                    console.error(
                        `[zustand persist middleware] IndexedDB error opening database ${dbName}:`,
                        request.error
                    )
                    dbPromise = null // Reset promise on error
                    reject(request.error)
                }
            } catch (error) {
                console.error(`[zustand persist middleware] Error initializing IndexedDB:`, error)
                dbPromise = null // Reset promise on error
                reject(error)
            }
        })

        return dbPromise
    }

    // Helper function to perform transaction operations
    async function performTransaction<T>(
        mode: IDBTransactionMode,
        action: (store: IDBObjectStore) => IDBRequest<T>
    ): Promise<T> {
        const db = await getDb()
        return new Promise<T>((resolve, reject) => {
            try {
                const transaction = db.transaction(storeName, mode)
                const store = transaction.objectStore(storeName)
                const request = action(store)

                request.onsuccess = () => {
                    resolve(request.result)
                }

                request.onerror = () => {
                    console.error(`[zustand persist middleware] IndexedDB request error:`, request.error)
                    reject(request.error)
                }

                transaction.onerror = () => {
                    console.error(`[zustand persist middleware] IndexedDB transaction error:`, transaction.error)
                    reject(transaction.error)
                }

                // Note: transaction.oncomplete is not always reliable for getting results,
                // rely on request.onsuccess for resolving the promise.
            } catch (error) {
                console.error(`[zustand persist middleware] Error during IndexedDB transaction:`, error)
                reject(error)
            }
        })
    }

    const persistStorage: PersistStorage<S> = {
        getItem: async (name: string): Promise<StorageValue<S> | null> => {
            try {
                const value = await performTransaction<StorageValue<S> | undefined>('readonly', (store) =>
                    store.get(name)
                )
                // IndexedDB returns undefined for non-existent keys, return null to match interface
                return value === undefined ? null : value
            } catch (error) {
                // Log error but return null to avoid breaking hydration
                console.error(`[zustand persist middleware] Error getting item "${name}" from IndexedDB:`, error)
                return null
            }
        },
        setItem: async (name: string, value: StorageValue<S>): Promise<unknown> => {
            try {
                // performTransaction returns the key on success for put, but we return void/unknown
                await performTransaction<IDBValidKey>(
                    'readwrite',
                    (store) => store.put(value, name) // Use name as the key
                )
            } catch (error) {
                // Log error but resolve promise to avoid unhandled rejections in middleware
                console.error(`[zustand persist middleware] Error setting item "${name}" in IndexedDB:`, error)
                return // Add return for TS error
            }
        },
        removeItem: async (name: string): Promise<unknown> => {
            try {
                await performTransaction<void>('readwrite', (store) => store.delete(name))
            } catch (error) {
                // Log error but resolve promise
                console.error(`[zustand persist middleware] Error removing item "${name}" from IndexedDB:`, error)
                return // Add return for TS error
            }
        },
    }

    return persistStorage
}
