import { useDoc } from '@tatsuokaniwa/swr-firestore'
import { getCleanData } from './utils'
import { useMemo } from 'react'

export default function useDocClean<T = any>(...args: Parameters<typeof useDoc<T>>) {
    const { data, ...rest } = useDoc<T>(...args)

    const cleanData = useMemo(() => {
        return getCleanData<T>(data) as T | undefined
    }, [data])

    return {
        data: cleanData,
        ...rest,
    }
}
