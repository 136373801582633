import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { Brands } from '@viscapmedia/viscap-schema-types'

interface BrandSelectionStore {
    currentBrandId: string | null
    setCurrentBrandId: (id: string | null) => void
    clearBrandSelection: () => void
}

const STORAGE_KEY = 'viscap-brand-selection'

export const useBrandSelectionStore = create<BrandSelectionStore>()(
    persist(
        (set) => ({
            currentBrandId: null,
            setCurrentBrandId: (id) => set({ currentBrandId: id }),
            clearBrandSelection: () => set({ currentBrandId: null }),
        }),
        {
            name: STORAGE_KEY,
            partialize: (state) => ({
                currentBrandId: state.currentBrandId,
            }),
        }
    )
)
