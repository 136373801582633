import { useQuery } from '@tanstack/react-query'
import { useDoc, useGetDoc } from '@tatsuokaniwa/swr-firestore'
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { getTeamsByIds, saveTeam } from '../api/firestore/teams'
import { getTeamTemplates, updateTemplate } from '../api/firestore/template'
import { getUsersByTeam } from '../api/firestore/users'
import { useAuth } from '../features/Auth/contexts/AuthContext'
import { Team, UserData } from '../types'
import { useTeamStore } from './stores/teamStore'
import useDocClean from '../hooks/swrFirestore/useDocClean'

interface TeamContextData {
    userTeams: Team[]
    currentTeam: Team | null
    reloadTeam: (id: string, isCurrent?: boolean) => Promise<void>
    currentTeamUsers: any[]
    currentTeamUsersLoading: boolean
    changeTeam: (newTeam: Team) => void
    handleUpdateCurrentTeamUsers: (users: any[]) => void
    handleUpdateTeam: (team: Team) => void
    templates: any[]
    handleUpdateTemplates: (templates: any[]) => void
    handleUpdateTemplate: (template: any) => void
    currentTeamOwnerId: string
    isOwner: boolean
    isViscapTeam: boolean
    isTalentView: boolean
    isTeamOwnerDeleted: boolean
    teamOwner: UserData | null
}

export const TeamContext = createContext<TeamContextData>(null!)

export const useTeamContext = () => useContext(TeamContext)

const TeamContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { userDB } = useAuth()

    const [userTeams, setUserTeams] = useState<Team[]>([])
    const [currentTeamId, setCurrentTeamId] = useState<string>('')
    const [currentTeamOwnerId, setCurrentTeamOwnerId] = useState<string>('')
    const [isOwner, setIsOwner] = useState<boolean>(false)

    const { team: storedTeam, setTeam: storeTeam } = useTeamStore()

    const { data: currentTeamDb = null } = useDocClean<Team>(
        userDB && currentTeamId
            ? {
                  path: `teams/${currentTeamId}`,
                  parseDates: ['createdAt'],
              }
            : null
    )

    const currentTeam = currentTeamDb || storedTeam

    useEffect(() => {
        if (currentTeamDb) {
            storeTeam(currentTeamDb)
        }
    }, [currentTeamDb, storeTeam])

    const { data: teamOwner = null } = useGetDoc<UserData>(
        currentTeamOwnerId
            ? {
                  path: `users/${currentTeamOwnerId}`,
                  parseDates: ['removeOn'],
              }
            : null
    )

    const isTalentView = useMemo(
        () => !!userDB && !!currentTeam && userDB?.role === 'talent' && currentTeam?.email === userDB?.email,
        [currentTeam, userDB]
    )

    const reloadTeam = useCallback(async (id: string, isCurrent?: boolean) => {}, [])

    const isViscapTeam = useMemo(() => {
        if (!currentTeam) return false

        return currentTeam.email.endsWith('@viscapmedia.com')
    }, [currentTeam])

    useEffect(() => {
        if (currentTeam && userDB) {
            const owner = currentTeam.users.find((u) => u.teamRole === 'owner')
            if (!owner) {
                console.error(`No owner found for team ${currentTeam.id}`)
            }
            setCurrentTeamOwnerId(owner.id)
            setIsOwner(owner.id === userDB.uid)
        }
    }, [currentTeam, userDB])

    useEffect(() => {
        if (currentTeam) {
            setUserTeams((prev) =>
                prev.map((team) => {
                    if (team.id === currentTeam.id) {
                        return currentTeam
                    }
                    return team
                })
            )
        }
    }, [currentTeam])

    const changeTeam = useCallback((newTeam) => {
        setCurrentTeamId(newTeam.id)
        localStorage.setItem('team', JSON.stringify(newTeam))
    }, [])

    const handleUpdateTeam = useCallback(async (updatedTeam) => {
        try {
            const { ref, exists, ...data } = updatedTeam
            await saveTeam(data)
        } catch (err) {
            console.log(err)
        }
    }, [])

    const { data: templates = [], refetch: refetchTemplates } = useQuery({
        queryKey: ['templates', currentTeam?.id],
        enabled: Boolean(currentTeam),
        queryFn: () => getTeamTemplates(currentTeam.id),
    })

    const handleUpdateTemplates = useCallback((templates) => refetchTemplates(), [refetchTemplates])

    const handleUpdateTemplate = useCallback(
        async (template) => {
            try {
                await updateTemplate(currentTeam.id, template)
                refetchTemplates()
            } catch (err) {
                console.log(err)
            }
        },
        [currentTeam, refetchTemplates]
    )

    const { data: teamsQuery } = useQuery({
        queryKey: ['teams', userDB?.teams],
        enabled: Boolean(userDB),
        queryFn: () => getTeamsByIds(userDB.teams.filter((t) => t?.invited !== true)),
    })

    useEffect(() => {
        if (userDB && teamsQuery) {
            setUserTeams(teamsQuery)
        }
    }, [userDB, teamsQuery])

    useEffect(() => {
        if (userTeams?.length > 0) {
            if (localStorage.getItem('team')) {
                setCurrentTeamId(
                    userTeams.find((b) => b.id === JSON.parse(localStorage.getItem('team')).id)?.id || userTeams[0].id
                )
            } else {
                setCurrentTeamId(userTeams[0].id)
                localStorage.setItem('team', JSON.stringify(userTeams[0]))
            }
        }
    }, [userTeams])

    const {
        data: currentTeamUsers = [],
        isLoading: currentTeamUsersLoading,
        refetch: refetchCurTeamUsers,
    } = useQuery({
        queryKey: ['currentTeamUsers', currentTeam?.id],
        enabled: Boolean(currentTeam),
        queryFn: async () => {
            const users = await getUsersByTeam(currentTeam.id)
            return users.filter((u) => !!u)
        },
    })

    const handleUpdateCurrentTeamUsers = useCallback((users) => refetchCurTeamUsers(), [refetchCurTeamUsers])

    const value = {
        userTeams,
        currentTeam,
        reloadTeam,
        currentTeamUsers,
        currentTeamUsersLoading,
        changeTeam,
        handleUpdateCurrentTeamUsers,
        handleUpdateTeam,
        templates,
        handleUpdateTemplates,
        handleUpdateTemplate,
        currentTeamOwnerId,
        isOwner,
        isViscapTeam,
        isTalentView,
        isTeamOwnerDeleted: !!teamOwner?.removeOn,
        teamOwner,
    }

    return <TeamContext.Provider value={value}>{children}</TeamContext.Provider>
}

export default TeamContextProvider
