import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useAuth } from '../features/Auth/contexts/AuthContext'
import { BrandLibraryTalentData, UserData } from '../types'
import { useRouter } from 'next/router'
import { useTeamContext } from './TeamContext'
import { getAllBrands, getBrand, getTalentBrands, getUserBrands, saveBrandFirestore } from '../api/firestore/brands'
import { getBrandProps, setBrandPropsFirestore } from '../api/firestore/props'
import { getBrandLocations, setBrandLocationsFirestore } from '../api/firestore/locations'
import { compact, omit, uniq } from 'lodash'
import { BrandTags, getBrandTags } from '../api/firestore/tags'
import { getTeam } from '../api/firestore/teams'
import { DocumentData, useGetDoc } from '@tatsuokaniwa/swr-firestore'
import { Brands } from '@viscapmedia/viscap-schema-types'
import useBrandTalent from '../hooks/useBrandTalent'
import useApprovedTalent from '../hooks/useApprovedTalent'
import { useQuery } from '@tanstack/react-query'
import useDocClean from '../hooks/swrFirestore/useDocClean'
import { useBrandsStore, isBrandsCacheValid } from './stores/brandsStore'
import { useBrandSelectionStore } from './stores/brandSelectionStore'

type IdeationConfig = {
    ideaStyles: string[]
    ideaTypes: string[]
    ideaSources: string[]
}

interface BrandContextData {
    brands: Brands[]
    pendingBrands: Brands[]
    brand: Brands | null
    saveBrand: (brand: Brands) => Promise<void>
    handleSetBrand: (brand: Brands) => void
    reloadBrand: (id: string, isCurrent?: boolean) => Promise<void>
    reloadBrands: () => Promise<any>
    brandLocations: string[]
    brandProps: string[]
    ownerTeamId: string
    brandOwnerId: string
    isViscapBrand: boolean
    brandTags: BrandTags
    isOwningTeam: boolean
    isBrandOwner: boolean
    isBrandOwnerDeleted: boolean
    brandOwner: UserData | null
    brandTalent: DocumentData<BrandLibraryTalentData>[]
    approvedTalent: BrandLibraryTalentData[]
    reloadProps: () => Promise<any>
    reloadLocations: () => Promise<any>
    reloadTags: () => Promise<any>
    updateBrandProps: (newProp: string) => void
    updateBrandLocations: (newLoc: string) => void
}

export const BrandContext = createContext<BrandContextData>(null!)

export const useBrandContext = () => useContext(BrandContext)

const BrandContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const router = useRouter()
    const { userDB } = useAuth()
    const { brandId } = router.query
    const { currentTeam, isTalentView, isViscapTeam, isOwner } = useTeamContext()
    const {
        brands: cachedBrands,
        pendingBrands: cachedPendingBrands,
        effectiveBrand,
        lastUpdated,
        setBrands,
        updateBrand,
        setEffectiveBrand,
    } = useBrandsStore()
    const { currentBrandId, setCurrentBrandId } = useBrandSelectionStore()

    const [ownerTeamId, setOwnerTeamId] = useState('')
    const [isViscapBrand, setIsViscapBrand] = useState(false)
    const [brandOwnerId, setBrandOwnerId] = useState('')

    const { data: brandOwner = null } = useGetDoc<UserData>(
        brandOwnerId
            ? {
                  path: `users/${brandOwnerId}`,
                  parseDates: ['removeOn'],
              }
            : null
    )

    const { data: brand = effectiveBrand } = useDocClean<Brands>(
        currentBrandId
            ? {
                  path: `brands/${currentBrandId}`,
              }
            : null
    )

    const { brandTalent } = useBrandTalent(brand?.id)
    const { approvedTalent } = useApprovedTalent(brand?.id)

    const handleSetBrand = useCallback(
        (brandData: Brands) => {
            if (!userDB) return
            setCurrentBrandId(brandData.id)
            setEffectiveBrand(brandData)
        },
        [userDB, setCurrentBrandId, setEffectiveBrand]
    )

    const {
        data: brandsQuery = cachedBrands,
        refetch: reloadBrands,
        isFetched,
    } = useQuery({
        queryKey: ['brands', currentTeam?.id, isTalentView, userDB],
        enabled: Boolean(currentTeam) || (isTalentView && Boolean(userDB)),
        queryFn: async () => {
            let brands: Brands[] = []

            if (isTalentView) {
                if (userDB?.talentProfileId) {
                    brands = await getTalentBrands(userDB.talentProfileId)
                }
            } else if (currentTeam?.brands?.length > 0) {
                if (currentTeam.email === 'support@viscapmedia.com') {
                    brands = await getAllBrands()
                } else {
                    brands = await getUserBrands(currentTeam.brands.map((b) => b.id))
                }
            }

            return brands
        },
    })

    useEffect(() => {
        if (currentTeam && isFetched) {
            const pendingBrandIds = currentTeam.brands?.filter((br) => br.isPending).map((br) => br.id) || []
            const activeBrands = brandsQuery.filter((br) => !pendingBrandIds.includes(br.id))
            const pendingBrands = brandsQuery.filter((br) => pendingBrandIds.includes(br.id))

            setBrands(activeBrands, pendingBrands)
        }
    }, [brandsQuery, isFetched, currentTeam, setBrands])

    const saveBrand = async (brandFields: Partial<Brands>) => {
        await saveBrandFirestore({ id: currentBrandId, ...brandFields })
        const updatedBrand = { ...effectiveBrand, ...brandFields }
        updateBrand(updatedBrand)
        setEffectiveBrand(updatedBrand)
    }

    const changeBrand = useCallback(
        (id: string) => {
            if (!cachedBrands.length) return

            const targetBrand = cachedBrands.find((b) => b.id === id)
            if (!targetBrand) return

            setCurrentBrandId(targetBrand.id)
            handleSetBrand(targetBrand)
        },
        [cachedBrands, handleSetBrand, setCurrentBrandId]
    )

    const reloadBrand = useCallback(
        async (id: string, isCurrent?: boolean) => {
            if (!userDB) return

            const updatedBrand = await getBrand(id)

            if (isCurrent) {
                setCurrentBrandId(updatedBrand.id)
                setEffectiveBrand(updatedBrand)
            }

            updateBrand(updatedBrand)
        },
        [userDB, updateBrand, setCurrentBrandId, setEffectiveBrand]
    )

    const { data: brandProps, refetch: reloadProps } = useQuery({
        queryKey: ['brandProps', brand?.id],
        enabled: Boolean(brand),
        queryFn: () => getBrandProps(currentBrandId),
    })

    const updateBrandProps = useCallback(
        async (newProp: string) => {
            if (!brand || !brandProps) return

            const newProps = uniq(compact([...brandProps, newProp]))

            try {
                await setBrandPropsFirestore(currentBrandId, newProps)
            } catch (e) {
                console.error(e)
            }

            reloadProps()
        },
        [brand, brandProps, reloadProps, currentBrandId]
    )

    const { data: brandLocations, refetch: reloadLocations } = useQuery({
        queryKey: ['brandLocations', brand?.id],
        enabled: Boolean(brand),
        queryFn: () => getBrandLocations(currentBrandId),
    })

    const updateBrandLocations = useCallback(
        async (newLoc: string) => {
            if (!brand || !brandLocations) return

            const newLocations = uniq(compact([...brandLocations, newLoc]))

            try {
                await setBrandLocationsFirestore(currentBrandId, newLocations)
            } catch (e) {
                console.error(e)
            }

            reloadLocations()
        },
        [brand, brandLocations, reloadLocations, currentBrandId]
    )

    const { data: brandTags, refetch: reloadTags } = useQuery({
        queryKey: ['brandTags', brand?.id],
        enabled: Boolean(brand),
        queryFn: async () => {
            const res = await getBrandTags(currentBrandId)
            return {
                clips: res?.clips || [],
                deliverables: res?.deliverables || [],
                elements: res?.elements || [],
                shootingSessions: res?.shootingSessions || [],
            } as BrandTags
        },
    })

    useEffect(() => {
        if (!cachedBrands.length || !userDB || !currentTeam) {
            return
        }

        if (router.query.switchBrand) {
            changeBrand(router.query.switchBrand as string)

            router.push({
                pathname: router.pathname,
                query: omit(router.query, 'switchBrand'),
            })

            return
        }

        // Handle direct brand switching via URL
        if (brandId) {
            const targetBrand = cachedBrands.find((b) => b.id === brandId)
            if (targetBrand) {
                setCurrentBrandId(targetBrand.id)
                setEffectiveBrand(targetBrand)
                return
            }
        }

        const hasAccess = (brandData) => {
            if (isViscapTeam && userDB.role !== 'talent') return true
            return brandData.teams.some((t) => t.id === currentTeam.id)
        }

        if (currentBrandId) {
            const targetBrand = cachedBrands.find((b) => b.id === currentBrandId)
            if (targetBrand && hasAccess(targetBrand)) {
                setEffectiveBrand(targetBrand)
                return
            }
        }

        const firstAvailableBrand = cachedBrands[0]
        setCurrentBrandId(firstAvailableBrand.id)
        setEffectiveBrand(firstAvailableBrand)
    }, [
        brand,
        brandId,
        cachedBrands,
        userDB,
        currentTeam,
        router.query,
        router,
        changeBrand,
        isViscapTeam,
        setCurrentBrandId,
        setEffectiveBrand,
        currentBrandId,
    ])

    useEffect(() => {
        const switchBrand = async () => {
            changeBrand(router.query.switchBrand as string)

            await router.push({
                pathname: router.pathname,
                query: omit(router.query, 'switchBrand'),
            })
        }

        if (!router.query.switchBrand || !brand?.id || router.query.switchBrand === brand.id) {
            return
        }

        switchBrand()
    }, [brand, changeBrand, reloadBrand, router])

    useEffect(() => {
        const getOwnerTeamInfo = async () => {
            const teamEntry = brand.teams?.find((t) => t.teamRole === 'owners')
            setOwnerTeamId(teamEntry?.id || '')

            if (teamEntry?.id) {
                const team = await getTeam(teamEntry.id)
                setIsViscapBrand(team.email.includes('@viscapmedia.com'))
                setBrandOwnerId(team.users.find((u) => u.teamRole === 'owner').id)
            }
        }

        if (brand) {
            getOwnerTeamInfo()
        }
    }, [brand])

    const isOwningTeam = useMemo(() => {
        if (brand && currentTeam) {
            return brand?.teams?.find((t) => t.id === currentTeam?.id)?.teamRole === 'owners'
        }

        return false
    }, [brand, currentTeam])

    const isBrandOwner = useMemo(() => isOwningTeam && isOwner, [isOwningTeam, isOwner])

    const value = {
        brands: cachedBrands,
        pendingBrands: cachedPendingBrands,
        brand: effectiveBrand,
        saveBrand,
        handleSetBrand,
        reloadBrand,
        reloadBrands,
        reloadProps,
        reloadLocations,
        reloadTags,
        brandLocations,
        updateBrandLocations,
        brandProps,
        updateBrandProps,
        ownerTeamId,
        brandOwnerId,
        isViscapBrand,
        brandTags,
        isOwningTeam,
        isBrandOwner,
        isBrandOwnerDeleted: !!brandOwner?.removeOn,
        brandOwner,
        brandTalent,
        approvedTalent,
    }
    return <BrandContext.Provider value={value}>{children}</BrandContext.Provider>
}

export default BrandContextProvider
