import { Timestamp } from '@firebase/firestore'
import { Children, isValidElement, ReactElement, ReactNode } from 'react'

export async function getHashedString(value) {
    const msgUint8 = new TextEncoder().encode(value)
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
    return hashHex
}

const stringToBoolean = {
    true: true,
    false: false,
}

export function convertToBoolean(value) {
    return stringToBoolean[value?.toLowerCase()] || false
}

export const dateFirestoreToJS = (timestamp: Timestamp | Date | number | null): Date | null => {
    if (timestamp && typeof timestamp['toDate'] === 'function') {
        return (timestamp as Timestamp).toDate()
    }

    if (timestamp instanceof Date) {
        return timestamp
    }

    if (typeof timestamp === 'number') {
        return new Date(timestamp * 1000)
    }

    return null
}

export const getReactChildrenTextContent = (children: ReactNode): string => {
    if (!children) return ''

    let text = ''

    Children.forEach(children, (child: ReactNode) => {
        if (typeof child === 'string' || typeof child === 'number') {
            text += child
        } else if (isValidElement(child)) {
            const element = child as ReactElement
            if (element.props.children) {
                text += getReactChildrenTextContent(element.props.children)
            } else if (element.props.value) {
                text += element.props.value
            }
        } else if (Array.isArray(child)) {
            text += getReactChildrenTextContent(child)
        }
    })

    return text.trim()
}

export function reorderArray<T>(array: T[], fromIndex: number, toIndex: number): T[] {
    const result = [...array];

    if (
        fromIndex < 0 ||
        fromIndex >= array.length ||
        toIndex < 0 ||
        toIndex >= array.length
    ) {
        return result;
    }

    const [itemToMove] = result.splice(fromIndex, 1);

    result.splice(toIndex, 0, itemToMove);

    return result;
}
