import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { createIndexedDBStorage } from '../../api/indexed-db/zustandIndexedDBStorage'
import { UserData } from '../../types'

interface UserStore {
    user: UserData
    setUser: (user: UserData) => void
    authToken: string
    setAuthToken: (authToken: string) => void
    authTokenUpdatedAt: number
    isAuthTokenStale: () => boolean
}

const STORAGE_KEY = 'viscap_ai_user_store'
const AUTH_TOKEN_STALE_TIME = 1000 * 60 * 60 * 1 //  1 hour

export const useUserStore = create<UserStore>()(
    persist(
        (set, get) => ({
            user: null,
            setUser: (user) =>
                set({
                    user,
                }),
            authToken: '',
            authTokenUpdatedAt: 0,
            setAuthToken: (authToken) =>
                set({
                    authToken,
                    authTokenUpdatedAt: Date.now(),
                }),
            isAuthTokenStale: () => Date.now() - get().authTokenUpdatedAt > AUTH_TOKEN_STALE_TIME,
        }),
        {
            name: STORAGE_KEY,
            partialize: (state) => ({
                user: state.user,
                authToken: state.authToken,
                authTokenUpdatedAt: state.authTokenUpdatedAt,
            }),
            storage: createIndexedDBStorage(),
        }
    )
)
