import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { createIndexedDBStorage } from '../../api/indexed-db/zustandIndexedDBStorage'
import { BrandLibraryTalentData } from '../../types' // Removed Framework
// Removed Talent import

interface ApprovedTalentStore {
    brandTalent: Record<string, BrandLibraryTalentData[]>
    setApprovedTalentStore: (brandId: string, talentData: BrandLibraryTalentData[]) => void
    getApprovedTalentForBrand: (brandId: string) => BrandLibraryTalentData[] // Simplified type
    lastUpdated: number | null
}

const STORAGE_KEY = 'viscap_ai_approved_talent_store'

// Corrected store name
export const useApprovedTalentStore = create<ApprovedTalentStore>()(
    persist(
        (set, get) => ({
            brandTalent: {},
            lastUpdated: null,
            setApprovedTalentStore: (brandId, talentData) =>
                set((state) => ({
                    brandTalent: {
                        ...state.brandTalent,
                        [brandId]: talentData,
                    },
                    lastUpdated: Date.now(),
                })),
            getApprovedTalentForBrand: (brandId) => get().brandTalent?.[brandId] || null,
        }),
        {
            name: STORAGE_KEY,
            partialize: (state) => ({
                brandTalent: state.brandTalent,
                lastUpdated: state.lastUpdated,
            }),
            storage: createIndexedDBStorage(),
        }
    )
)
